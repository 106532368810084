import Menu from "./UI/Menu";
import { Route, Routes } from "react-router-dom";
import router from "../router";
import useUserPermissions from "../contexts/UserPermissionsContext/useUser";

export default function Layout() {
  const { address } = useUserPermissions();

  return (
    address && (
      <div className="container">
        <Menu />
        <div className="content">
          <Routes>
            {router.map((el) => (
              <Route {...el} key={el.path} />
            ))}
          </Routes>
        </div>
      </div>
    )
  );
}
