import { ContractNames as MultisigContractNames } from "@airdao/airdao-node-contracts";
import { submitTransaction } from "./submitTransaction";

export const submitBondMarketplaceMultisigTx = async (
  contracts,
  address,
  calldata,
  value,
) => {
  const multisig = contracts.getContractByName(
    MultisigContractNames.Ecosystem_BondMarketplaceMultisig,
  );
  return await submitTransaction(multisig, address, value || 0, calldata);
};
