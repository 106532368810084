export default [
  {
    inputs: [
      {
        internalType: "contract IEclipseManager",
        name: "_manager",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "liquidity",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "avaliableLiquidity",
        type: "uint256",
      },
    ],
    name: "InsufficientLiquidity",
    type: "error",
  },
  {
    inputs: [],
    name: "NotAManager",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint48",
        name: "percent",
        type: "uint48",
      },
    ],
    name: "DefaultPremiumPercentSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint48",
        name: "percent",
        type: "uint48",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "poolLifetime",
        type: "uint256",
      },
    ],
    name: "PremiumPercentSet",
    type: "event",
  },
  {
    inputs: [],
    name: "DECIMALS",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "DEFAULT_PREMIUM_PERCENT",
    outputs: [
      {
        internalType: "uint48",
        name: "",
        type: "uint48",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract IAstraPair",
        name: "pair",
        type: "address",
      },
    ],
    name: "calculateAllowedLiquidity",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountProvided",
        type: "uint256",
      },
      {
        internalType: "contract IAstraPair",
        name: "pair",
        type: "address",
      },
    ],
    name: "calculateCounterpart",
    outputs: [
      {
        internalType: "uint256",
        name: "counterpart",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "contract IAstraPair",
        name: "pair",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amountLP",
        type: "uint256",
      },
    ],
    name: "calculateReturns",
    outputs: [
      {
        internalType: "uint256",
        name: "userPart",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "projectPart",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "premiumPart",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "expectedUserAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "expectedTreasuryAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "poolLifetime",
        type: "uint256",
      },
    ],
    name: "getPremiumPercent",
    outputs: [
      {
        internalType: "uint48",
        name: "percent",
        type: "uint48",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "manager",
    outputs: [
      {
        internalType: "contract IEclipseManager",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "premiumPercents",
    outputs: [
      {
        internalType: "uint48",
        name: "percent",
        type: "uint48",
      },
      {
        internalType: "uint256",
        name: "poolLifetime",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "premiumsLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint48",
        name: "percent",
        type: "uint48",
      },
    ],
    name: "setDefaultPremiumPercent",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint48",
        name: "percent",
        type: "uint48",
      },
      {
        internalType: "uint256",
        name: "poolLifetime",
        type: "uint256",
      },
    ],
    name: "setPremiumPercent",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
