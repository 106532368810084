import useContracts from "../../../../contexts/ContractsContext/useContracts";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import { ContractNames } from "@airdao/bond-periphery";
import {
  rewardVaultMethods,
  userBMPurchaserMethods,
} from "../../../../utils/constants";
// import { useMemo, useState } from "react";
import { Form } from "../../../UI/Form";
import { useEffect, useState } from "react";
// import UiSelect from "../../../UI/UiSelect";
// import { useRolesData } from "./hooks/useRolesData";
import { Input } from "../../../UI/Inputs";
import { ethers } from "ethers";
import { submitTransaction } from "../../../../utils/submitTransaction";
import { submitBondMarketplaceMultisigTx } from "../../../../utils/submitBondMarketplaceMultisigTx";
// import useUserPermissions from "../../../../contexts/UserPermissionsContext/useUser";

const Reward = () => {
  const { peripheryContracts, contracts } = useContracts();

  const [fundAmount, setFundAmount] = useState("");

  const rewardVaultContract = peripheryContracts.getContractByName(
    ContractNames.RewardVault,
  );

  const handleFundVault = async () => {
    const parsedAmount = ethers.utils.parseEther(fundAmount);
    const params = [parsedAmount];
    const rewardTokenAddress = await rewardVaultContract.rewardToken();

    if (rewardTokenAddress === ethers.constants.AddressZero) {
      params.push({ value: parsedAmount });
    }
    const { data } = await rewardVaultContract.populateTransaction.fundVault(
      ...params,
    );

    const tx = await submitBondMarketplaceMultisigTx(
      contracts,
      rewardVaultContract.address,
      data,
      rewardTokenAddress === ethers.constants.AddressZero ? parsedAmount : 0,
    );
    return await tx.wait();
  };

  return (
    <>
      <ContractMethodsForm
        contract={rewardVaultContract}
        methods={rewardVaultMethods.setter}
        label="Setters"
      />
      <div className="page">
        <Form handler={handleFundVault} contract={rewardVaultContract}>
          <h3>Method: fundVault</h3>
          <p>Amount</p>
          <Input
            value={fundAmount}
            placeholder="0"
            setValue={setFundAmount}
            type="number"
          />
        </Form>
      </div>
      <ContractMethodsForm
        contract={rewardVaultContract}
        methods={rewardVaultMethods.pauser}
        label="Pausers"
      />
      <ContractMethodsForm
        contract={peripheryContracts.getContractByName(
          ContractNames.UserBMPurchaser,
        )}
        methods={userBMPurchaserMethods}
        label={ContractNames.UserBMPurchaser}
      />
    </>
  );
};

export default Reward;
