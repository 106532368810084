import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { ContractNames } from "@airdao/bond-periphery";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import { kosTokenMethods } from "../../../../utils/constants";

const Token = () => {
  const { peripheryContracts } = useContracts();

  return (
    <ContractMethodsForm
      label={ContractNames.StakingToken}
      methods={kosTokenMethods}
      contract={peripheryContracts.getContractByName(
        ContractNames.StakingToken,
      )}
    />
  );
};

export default Token;
