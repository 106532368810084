import { API_ENDPOINTS } from "./endpoints";
import axios from "axios";

const baseUrl = {
  16718: "https://bond-backend-api.ambrosus.io/v2/api",
  22040: "https://bond-backend-api.ambrosus-test.io/v2/api",
  30746: "https://bond-backend-api.ambrosus-dev.io/v2/api",
};
export const getBalancyByContract = (address, chainId) =>
  axios
    .get(`${baseUrl[chainId]}${API_ENDPOINTS.BALANCE}?address=${address}`)
    .then((res) => res.data);

export const getBalancyBeneficiary = (chainId) =>
  axios
    .get(`${baseUrl[chainId]}${API_ENDPOINTS.BENEFICIARY}`)
    .then((res) => res.data);
