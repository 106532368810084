import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { useEffect, useMemo, useRef, useState } from "react";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { Form } from "../../../UI/Form";
import { ethers, utils } from "ethers";
import { Input } from "../../../UI/Inputs";
import { ContractNames as MultisigContractNames } from "@airdao/airdao-node-contracts/dist/src/contracts/names";
import { submitTransaction } from "../../../../utils/submitTransaction";
import UiSelect from "../../../UI/UiSelect";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import abi from "../../../../utils/abi/harbor";
import StorageRewards from "../liquid-staking/components/StorageRewards";

const Harbor = () => {
  const { contracts, provider } = useContracts();

  const contract = contracts.getContractByName(
    ContractNames.Ecosystem_LimitedTokenPoolsManager,
  );
  const multisig = contracts.getContractByName(
    MultisigContractNames.Ecosystem_LimitedTokenPoolsManagerMultisig,
  );

  const [pools, setPools] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);

  const [createPoolData, setCreatePoolData] = useState({
    name: "",
    limitsMultiplierToken: "",
    profitableToken: "",
    rewardToken: "",
  });

  const [configurePoolData, setConfigurePoolData] = useState({
    rewardTokenPrice: "",
    interest: "",
    minDepositValue: "",
    minStakeValue: "",
    stakeLockPeriod: "",
    maxTotalStakeValue: "",
    maxStakePerUserValue: "",
    stakeLimitsMultiplier: "",
  });

  useEffect(() => {
    initPoolsList();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedPool) {
        const pool = new ethers.Contract(selectedPool, abi, provider);
        const poolData = await pool.limitsConfig();

        const interest = +utils.formatUnits(poolData.interest, 0);
        const r = interest / 1000000000;
        const periodsPerYear = ((365 * 24 * 60 * 60) / 3600) * r;
        const periodsPerYearRounded = Math.ceil(periodsPerYear * 10000) / 100;

        setConfigurePoolData({
          rewardTokenPrice: utils.formatUnits(poolData.rewardTokenPrice, 9),
          interest: periodsPerYearRounded,
          minDepositValue: utils.formatEther(poolData.minDepositValue),
          minStakeValue: utils.formatEther(poolData.minStakeValue),
          stakeLockPeriod: +utils.formatUnits(poolData.stakeLockPeriod, 0) / 60,
          maxTotalStakeValue: utils.formatEther(poolData.maxTotalStakeValue),
          maxStakePerUserValue: utils.formatEther(
            poolData.maxStakePerUserValue,
          ),
          stakeLimitsMultiplier: +utils.formatUnits(
            poolData.stakeLimitsMultiplier,
            9,
          ),
        });
      }
    })();
  }, [selectedPool]);

  const methods = useMemo(() => {
    if (!selectedPool) {
      return [];
    }
    return [
      `activatePool(string=${selectedPool}:Pool name)`,
      `deactivatePool(string=${selectedPool}:Pool name)`,
    ];
  }, [selectedPool]);

  const initPoolsList = async () => {
    const lockEvents = await contract.queryFilter(
      contract.filters.LimitedPoolCreated(),
    );
    setPools(
      lockEvents.map((el) => ({
        value: el.args.pool,
        label: el.args.name,
      })),
    );
  };

  const handleData = (event) => {
    const { name, value } = event.target;
    setCreatePoolData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleConfigureData = (event) => {
    const { name, value } = event.target;
    setConfigurePoolData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleCreate = async () => {
    const { data } =
      await contract.populateTransaction.createPool(createPoolData);

    const tx = await submitTransaction(multisig, contract.address, 0, data);
    return await tx.wait();
  };

  const handleConfigure = async () => {
    const {
      rewardTokenPrice,
      interest,
      minDepositValue,
      minStakeValue,
      stakeLockPeriod,
      maxTotalStakeValue,
      maxStakePerUserValue,
      stakeLimitsMultiplier,
    } = configurePoolData;

    const billion = 1000000000;

    const { data } = await contract.populateTransaction.configurePool(
      selectedPool,
      {
        rewardTokenPrice: +rewardTokenPrice * billion,
        interest: Math.floor((+interest * 10000000) / (365 * 24)),
        interestRate: 3600,
        minDepositValue: utils.parseEther(minDepositValue),
        minStakeValue: utils.parseEther(minStakeValue),
        fastUnstakePenalty: 0,
        unstakeLockPeriod: 0,
        stakeLockPeriod: +stakeLockPeriod * 60,
        maxTotalStakeValue: utils.parseEther(maxTotalStakeValue),
        maxStakePerUserValue: utils.parseEther(maxStakePerUserValue),
        stakeLimitsMultiplier: Math.floor(stakeLimitsMultiplier * billion),
      },
    );

    const tx = await submitTransaction(multisig, contract.address, 0, data);
    return await tx.wait();
  };

  return (
    <>
      <div className="page">
        <Form contract={contract} handler={handleCreate}>
          <h3>Method: createPool</h3>
          <Input
            value={createPoolData.pair}
            setValue={handleData}
            title="Name"
            name="name"
            placeholder="Pool name"
            returnEvent
          />
          <Input
            value={createPoolData.limitsMultiplierToken}
            setValue={handleData}
            title="Limits multiplier token"
            name="limitsMultiplierToken"
            placeholder={ethers.constants.AddressZero}
            returnEvent
          />
          <Input
            value={createPoolData.profitableToken}
            setValue={handleData}
            title="Profitable token"
            name="profitableToken"
            placeholder={ethers.constants.AddressZero}
            returnEvent
          />
          <Input
            value={createPoolData.rewardToken}
            setValue={handleData}
            title="Reward token"
            name="rewardToken"
            placeholder={ethers.constants.AddressZero}
            returnEvent
          />
        </Form>
        <h3>Select pool for managing</h3>
        <UiSelect
          options={pools}
          selectedValue={selectedPool}
          onChange={setSelectedPool}
        />
      </div>
      {selectedPool && (
        <ContractMethodsForm
          contract={contract}
          methods={methods}
          multisig={
            MultisigContractNames.Ecosystem_LimitedTokenPoolsManagerMultisig
          }
          label="Pool manager"
        >
          <Form contract={contract} handler={handleConfigure}>
            <h3>Method: configurePool</h3>
            <Input
              type="number"
              value={configurePoolData.rewardTokenPrice}
              setValue={handleConfigureData}
              title="Reward token price"
              name="rewardTokenPrice"
              placeholder="0"
              returnEvent
            />
            <Input
              type="number"
              value={configurePoolData.interest}
              setValue={handleConfigureData}
              title="APY %"
              name="interest"
              placeholder="0"
              returnEvent
            />
            <Input
              type="number"
              value={configurePoolData.minDepositValue}
              setValue={handleConfigureData}
              title="Min deposit"
              name="minDepositValue"
              placeholder="0"
              returnEvent
            />
            <Input
              type="number"
              value={configurePoolData.minStakeValue}
              setValue={handleConfigureData}
              title="Min stake"
              name="minStakeValue"
              placeholder="0"
              returnEvent
            />
            <Input
              type="number"
              value={configurePoolData.stakeLockPeriod}
              setValue={handleConfigureData}
              title="Stake lock period (minutes)"
              name="stakeLockPeriod"
              placeholder="0"
              returnEvent
            />
            <Input
              type="number"
              value={configurePoolData.maxTotalStakeValue}
              setValue={handleConfigureData}
              title="Max total stake"
              name="maxTotalStakeValue"
              placeholder="0"
              returnEvent
            />
            <Input
              type="number"
              value={configurePoolData.maxStakePerUserValue}
              setValue={handleConfigureData}
              title="Max stake per user"
              name="maxStakePerUserValue"
              placeholder="0"
              returnEvent
            />
            <Input
              type="number"
              value={configurePoolData.stakeLimitsMultiplier}
              setValue={handleConfigureData}
              title="Stake limits multiplier"
              name="stakeLimitsMultiplier"
              placeholder="0"
              returnEvent
            />
          </Form>
        </ContractMethodsForm>
      )}
      <StorageRewards
        contract={ContractNames.Ecosystem_LimitedTokenPoolsManagerRewardsBank}
        label="Rewards bank"
        multisigName={ContractNames.Ecosystem_LimitedTokenPoolsManagerMultisig}
      />
    </>
  );
};

export default Harbor;
