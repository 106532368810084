import React, { useState } from "react";
import chevron from "../../assets/svg/chevron.svg";
import { NavLink, useNavigate } from "react-router-dom";

const GroupItem = ({ name, options, redirectTo }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const handleExpanded = () => {
    if (redirectTo) {
      navigate(redirectTo);
    } else {
      setIsExpanded((state) => !state);
    }
  };

  return (
    <li className="nav-menu__group-item">
      <button
        type="button"
        className="nav-menu__group-item-btn"
        onClick={handleExpanded}
      >
        {name}
        {!!options.length && (
          <img
            className={`nav-menu__group-item-arrow ${
              isExpanded ? "nav-menu__group-item-arrow_open" : ""
            }`}
            src={chevron}
            alt="arrow"
          />
        )}
      </button>
      <ul
        className={`nav-menu__options ${
          isExpanded ? "nav-menu__options_expanded" : ""
        }`}
      >
        {options.map(({ value, text, url }) => (
          <li key={url}>
            <NavLink
              value={value}
              className={({ isActive }) =>
                `nav-menu__option ${
                  isActive ? "nav-menu__option_selected" : ""
                }`
              }
              to={url}
            >
              {text.replace("_Multisig", "").replace("Ecosystem_", "")}
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default GroupItem;
