import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { ethers, utils } from "ethers";
import abi from "../../../../utils/abi/astra";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import { ContractNames } from "@airdao/airdao-node-contracts";
import {
  astraChronosMethods,
  astraTokenMethods,
} from "../../../../utils/constants";
import { useEffect, useRef, useState } from "react";
import { Input } from "../../../UI/Inputs";
import { submitLiquidStakingMultisigTx } from "../../../../utils/submitLiquidStakingMultisigTx";
import { submitAstraMultisigTx } from "../../../../utils/submitAstraMultisigTx";
import { contracts } from "@airdao/bond-periphery";
import { Form } from "../../../UI/Form";
import Withdraw from "./Withdraw";

const Chronos = () => {
  const { contracts, chainId, provider } = useContracts();

  const contract = useRef(
    new ethers.Contract(
      abi[chainId].ChronosTrade.address,
      abi[chainId].ChronosTrade.abi,
      provider.getSigner(),
    ),
  );

  const [percent, setPercent] = useState("");
  const [currentPercent, setCurrentPercent] = useState("");
  const [feeDecimal, setFeeDecimal] = useState("");

  useEffect(() => {
    contract.current.feePercent().then(setCurrentPercent);
    contract.current.FEE_DECIMALS().then(setFeeDecimal);
  }, []);

  const handleFeePercent = async () => {
    const { data } = await contract.current.populateTransaction.setFee(
      utils.parseUnits((+percent / 100).toString(), feeDecimal),
    );

    const tx = await submitAstraMultisigTx(
      contracts,
      contract.current.address,
      data,
    );
    return await tx.wait();
  };
  return (
    <ContractMethodsForm
      multisig={ContractNames.Ecosystem_AstradexMultisig}
      contract={contract.current}
      methods={astraChronosMethods}
      label="Chronos"
    >
      <Withdraw />
      <Form handler={handleFeePercent} contract={contract.current}>
        <div>
          <h3>Method: setFee</h3>
          <p>
            Current:{" "}
            {!!currentPercent &&
              !!feeDecimal &&
              utils.formatUnits((+currentPercent * 100).toString(), feeDecimal)}
            %
          </p>
        </div>
        <Input
          type="number"
          value={percent}
          setValue={setPercent}
          title="Fee"
          placeholder="0%"
        />
      </Form>
    </ContractMethodsForm>
  );
};

export default Chronos;
