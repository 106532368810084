import React from "react";

const NoMethodChosenPage = () =>
		<section className='no-method-page'>
			<svg
					className='no-method-page__icon'
					width="31"
					height="38"
					viewBox="0 0 31 38"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
			>
				<path
						d="M1.5 23.6667L17.5 1V14.3333H29.5L14.1667 37V23.6667H1.5Z"
						stroke="#A2B2D8"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
				/>
			</svg>

			<p className='no-method-page__text'>
				Select the multisig option
			</p>

		</section>

export default NoMethodChosenPage;
