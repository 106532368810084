import { ContractNames } from "@airdao/airdao-node-contracts";

export default function getContractInfo(contracts, address) {
  const groupContractName = contracts.getNameByAddressSafe(address);
  if (!groupContractName) {
    return {
      type: "undefined",
      group: null,
    };
  }
  const contractInfo = contractsDict[groupContractName];
  if (!contractInfo) {
    return {
      type: prettifyContractType(groupContractName),
      group: null,
    };
  }
  return contractInfo;
}

function prettifyContractType(contractName) {
  return contractName
    .replace("_Multisig", "")
    .replace(/([a-z])([A-Z0-9])/g, "$1 $2");
}

const contractsDict = {
  [ContractNames.Ecosystem_MasterMultisig]: {
    type: "Permissions",
    group: null,
  },
  [ContractNames.FinanceMasterMultisig]: {
    type: "Finance",
    group: "Master",
  },
  [ContractNames.FinanceRewardsMultisig]: {
    type: "Finance",
    group: "Rewards",
  },
  [ContractNames.FinanceInvestorsMultisig]: {
    type: "Finance",
    group: "Investors",
  },
  [ContractNames.FinanceTeamMultisig]: {
    type: "Finance",
    group: "Team",
  },
  [ContractNames.FinanceEcosystemMultisig]: {
    type: "Finance",
    group: "Ecosystem",
  },
};
