const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};

export const displayAmount = (n, dp) => {
  return isFloat(+n)
    ? +`${n.toString().split('.')[0]}.${n
        .toString()
        .split('.')[1]
        .substring(0, dp)}`
    : Number(n).toFixed(2);
};

export const isDecimalString = (value) => {
  const pattern = /^[0-9]*(|[.][0-9]{1,10})$/;
  return pattern.test(value);
};

export const isValidAmount = (value) => {
  return value && value !== '0' && isDecimalString(value);
};

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
