import { submitTransaction } from "./submitTransaction";

export default async function (
  tokenContract,
  amountBn,
  multisig,
  approveAddress,
) {
  const tx = await tokenContract.transfer(multisig.address, amountBn);
  await tx.wait();

  const { data } = await tokenContract.populateTransaction.approve(
    approveAddress,
    amountBn,
  );

  const tx2 = await submitTransaction(multisig, tokenContract.address, 0, data);
  return await tx2.wait();
}
