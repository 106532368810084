export async function submitTransaction(
  multisig,
  destination,
  value,
  calldata,
) {
  try {
    await multisig.callStatic.checkBeforeSubmitTransaction(
      destination,
      value,
      calldata,
      { value },
    );
    throw new Error(
      "checkBeforeSubmitTransaction doesn't respond with any error, but it should!",
    );
  } catch (e) {
    console.log(e);
    const errorReason = (e.error || e).toString();
    if (errorReason !== "OK" && errorReason !== "Error: OK") throw e;
  }

  return await multisig.submitTransaction(destination, value, calldata, {
    value,
  });
}
