import { useEffect, useRef, useState } from "react";
import { ContractNames } from "@airdao/airdao-node-contracts";
import abi from "../../../utils/abi/staking";
import { ethers, utils } from "ethers";
import { Form } from "../../UI/Form";
import Datetime from "react-datetime";
import moment from "moment";
import { submitTransaction } from "../../../utils/submitTransaction";
import { Input, InputAddress, InputNumber } from "../../UI/Inputs";
import useContracts from "../../../contexts/ContractsContext/useContracts";
import useStakingContractData from "./useStakingContractData";
import ContractMethodsForm from "../../UI/ContractMethodsForm";

const RewardBank = () => {
  const { contracts, provider, chainId } = useContracts();

  const { current: rewardBankContract } = useRef(
    new ethers.Contract(
      abi.StarfleetStaking_RewardsBank[+chainId].address,
      abi.StarfleetStaking_RewardsBank[+chainId].abi,
      provider.getSigner(),
    ),
  );
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState("");

  useEffect(() => {
    provider.getBalance(rewardBankContract.address).then(setBalance);
  }, []);

  const handleSubmit = async () => {
    const multisig = contracts.getContractByName(
      ContractNames.Ecosystem_StarfleetMultisig,
    );
    const { data } = await rewardBankContract.populateTransaction.withdrawAmb(
      address,
      utils.parseUnits(amount, 18),
    );
    return await (
      await submitTransaction(multisig, rewardBankContract.address, 0, data)
    ).wait();
  };

  return (
    <div className="page">
      <div className="finance-transfer-heading">
        <h3>StarfleetStaking_RewardsBank</h3>
        <span className="finance-transfer-heading__address">
          {rewardBankContract.address}
        </span>
      </div>
      <p>Balance: {balance && utils.formatUnits(balance, 18)} AMB</p>
      <Form handler={handleSubmit} contract={rewardBankContract}>
        <h3>Withdraw AMB</h3>
        <p>Address to:</p>
        <InputAddress
          setValue={setAddress}
          value={address}
          placeholder="Address to"
        />
        <p>Amount:</p>
        <Input setValue={setAmount} value={amount} placeholder="0" />
      </Form>
    </div>
  );
};

const Staking = () => {
  const { contracts, provider, chainId } = useContracts();

  const { current: stakingContract } = useRef(
    new ethers.Contract(
      abi.StarfleetStaking[+chainId].address,
      abi.StarfleetStaking[+chainId].abi,
      provider.getSigner(),
    ),
  );

  const { stakingData, balance } = useStakingContractData(stakingContract);
  const [date, setDate] = useState(null);
  const [apy, setAPY] = useState("");
  const [delay, setDelay] = useState("");
  const [minStake, setMinStake] = useState("");

  const handleSubmit = async (method, params) => {
    const multisig = contracts.getContractByName(
      ContractNames.Ecosystem_StarfleetMultisig,
    );
    const { data } = await stakingContract.populateTransaction[method](
      ...params,
    );
    return await (
      await submitTransaction(multisig, stakingContract.address, 0, data)
    ).wait();
  };

  const handleDate = (e) => {
    setDate(moment(e).unix());
  };

  const submitAPY = async () => {
    const param = +apy * 10000;
    return await handleSubmit("setProfitability", [param]);
  };

  const submitDelay = async () => {
    const param = +delay * 60;
    return await handleSubmit("setUnstakePeriod", [param]);
  };

  const submitDateA = async () => {
    return await handleSubmit("setDayA", [date]);
  };

  const submitMinStake = async () => {
    return await handleSubmit("setMinStake", [minStake]);
  };

  return (
    <>
      <div className="page">
        <Form handler={submitDateA}>
          <div className="finance-transfer-heading">
            <h3>StarfleetStaking</h3>
            <span className="finance-transfer-heading__address">
              {stakingContract.address}
            </span>
          </div>
          <p className="staking-balance">
            Balance: {balance && utils.formatUnits(balance, 18)} AMB
          </p>
          <h3>TGE date</h3>
          <p>
            Current:{" "}
            {moment(+stakingData.date * 1000).format("DD/MM/YYYY HH:mm")}
          </p>
          <Datetime
            onChange={handleDate}
            dateFormat="DD/MM/YYYY"
            timeFormat="HH:mm"
          />
        </Form>
        <Form handler={submitAPY} contract={stakingContract}>
          <h3>Profit APY%</h3>
          <p>Current: {stakingData.profitability}%</p>
          <InputNumber
            value={apy}
            setValue={setAPY}
            inputProps={{ placeholder: "0" }}
          />
        </Form>
        <Form handler={submitDelay} contract={stakingContract}>
          <h3>Withdrawal delay in minutes</h3>
          <p>Current: {+stakingData.delay / 60}min</p>
          <InputNumber
            value={delay}
            setValue={setDelay}
            inputProps={{ placeholder: "0" }}
          />
        </Form>
        <Form handler={submitMinStake} contract={stakingContract}>
          <h3>Min stake</h3>
          <InputNumber
            value={minStake}
            setValue={setMinStake}
            inputProps={{ placeholder: "0" }}
          />
        </Form>
      </div>
      <RewardBank />
    </>
  );
};

export default Staking;
