import React from 'react';
import { ReactComponent as Arrow } from '../../assets/svg/arrow.svg';

import InlineLoader from './InlineLoader';

const Button = ({
  isLoading,
  children,
  withArrow,
  className,
  ...restProps
}) => (
  <button
    className={`ui-button ${className} ${isLoading ? 'ui-button_loading' : ''}`}
    {...restProps}
  >
    {isLoading ? <InlineLoader /> : children}
    {withArrow ? <Arrow /> : null}
  </button>
);

export default Button;
