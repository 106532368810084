import useContracts from "../../../../../contexts/ContractsContext/useContracts";
import ContractMethodsForm from "../../../../UI/ContractMethodsForm";
import { liquidStakingWithdrawalDelayMethods } from "../../../../../utils/constants";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { useEffect, useState } from "react";
import { utils } from "ethers";

const WithdrawalDelay = () => {
  const { contracts } = useContracts();

  const [currentDelay, setCurrentDelay] = useState("");

  const contract = contracts.getContractByName(
    ContractNames.Ecosystem_LiquidPool,
  );

  useEffect(() => {
    contract
      .unstakeLockTime()
      .then((res) => setCurrentDelay(utils.formatUnits(res, 0)));
  }, []);

  return (
    <ContractMethodsForm
      methods={liquidStakingWithdrawalDelayMethods}
      contract={contract}
      label="Withdrawal delay"
      multisig={ContractNames.Ecosystem_LiquidPoolMultisig}
    >
      <p>
        Current delay: <b>{currentDelay / 86400} days</b>
      </p>
    </ContractMethodsForm>
  );
};

export default WithdrawalDelay;
