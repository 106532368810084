import { useState, useCallback, useEffect } from "react";

import { getBalancyBeneficiary, getBalancyByContract } from "../../services";

const useGetBalanceByContract = (chainId) => {
  const [feeBalances, setFeeBalances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [address, setAddress] = useState("");

  const getBalances = useCallback(async () => {
    if (!address) return;
    try {
      setIsLoading(true);
      const response = await getBalancyByContract(address, chainId);
      if (response) {
        setFeeBalances(response);
      }
    } catch (e) {
      console.error("Error while getBalances: ", e);
    } finally {
      setIsLoading(false);
    }
  }, [address]);

  useEffect(() => {
    (async function () {
      if (!chainId) {
        return;
      }
      try {
        const result = await getBalancyBeneficiary(chainId);
        if (Object.keys(result).length > 0) {
          setAddress(Object.values(result)[0]);
        }
      } catch (e) {
        console.error("Error while getBalancyBeneficiary: ", e);
      }
    })();
  }, [chainId]);

  useEffect(() => {
    getBalances();
  }, [getBalances]);

  return { getBalances, setAddress, address, feeBalances, isLoading };
};

export default useGetBalanceByContract;
