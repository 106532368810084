import React, {useState} from 'react';
import HandleUserModal from "../../HandleUserModal";

const UsersItem = ({ userData, contracts, isInitiator }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);

  const handleOpen = () => setIsEditOpen((state) => !state);

  return (
    <div className='user-list-item'>
      <span>{userData.address}</span>
      {isInitiator && (
        <button className='user-list-item__btn' onClick={handleOpen}>
          Edit
        </button>
      )}
      {isEditOpen && (
        <HandleUserModal
          handleOpen={handleOpen}
          userData={userData}
          contracts={contracts}
        />
      )}
    </div>
  );
};

export default UsersItem;
