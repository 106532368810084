import { useState } from "react";
import { ethers } from "ethers";

export function useContractCall(call, contract) {
  const [result, setResult] = useState({
    isLoading: false,
    error: undefined,
    result: undefined,
  });

  const submit = async (...args) => {
    setResult({ isLoading: true });
    try {
      const result = await call(...args);
      setResult({ isLoading: false, result });
    } catch (error) {
      let errorMsg = error;

      const errorSignatureMatch = error.message.match(
        /Reverted (0x[a-fA-F0-9]{8})/,
      );
      const errorSignature = errorSignatureMatch
        ? errorSignatureMatch[1]
        : null;

      if (contract) {
        for (const fragment of contract.interface.fragments) {
          if (
            fragment.type === "error" &&
            contract.interface.getSighash(fragment) === errorSignature
          ) {
            errorMsg = fragment.name;
            break;
          }
        }
      }

      setResult({ isLoading: false, error: errorMsg });
    }
  };

  return [result, submit];
}
