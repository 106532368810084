import React, { useEffect, useState } from "react";
import UsersItem from "./UsersItem";
import GroupsItem from "./GroupsItem";
import { ContractNames, Multisig } from "@airdao/airdao-node-contracts";
import HandleUserModal from "../../HandleUserModal";
import useUser from "../../../contexts/UserPermissionsContext/useUser";
import useContracts from "../../../contexts/ContractsContext/useContracts";

const tabs = ["Users", "Groups"];

const Management = () => {
  const { contracts } = useContracts();
  const { address } = useUser();

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setUsers] = useState({});
  const [groups, setGroups] = useState({});
  const [isInitiator, setIsInitiator] = useState(false);

  useEffect(() => {
    if (contracts) {
      init();
    }
  }, [contracts]);

  const init = async () => {
    const permissions = await Multisig.getPermissions(contracts);

    setUsers(permissions.users);
    setGroups(permissions.groups);

    const currentUser = permissions.users[address];
    const multisigAddress = contracts.getContractByName(
      ContractNames.Ecosystem_MasterMultisig,
    ).address;

    const currentGroup = currentUser.groups.find(
      (el) => el.address === multisigAddress,
    );

    if (currentGroup && currentGroup.isInitiator) {
      setIsInitiator(true);
    }
  };

  const handleModal = () => setIsModalOpen((state) => !state);

  return (
    <>
      <div className="page page_wide tabs-heading">
        {tabs.map((el) => (
          <button
            key={el}
            type="button"
            onClick={() => setSelectedTab(el)}
            className={`tabs-btn ${
              selectedTab === el ? "tabs-btn_selected" : ""
            }`}
          >
            {el}
          </button>
        ))}
        {isInitiator && (
          <button
            type="button"
            onClick={handleModal}
            className="heading-button"
          >
            Add user +
          </button>
        )}
      </div>
      <div className="page page_wide owners-page-content">
        {selectedTab === "Users"
          ? Object.keys(users).map((el) => (
              <UsersItem
                userData={users[el]}
                key={el}
                isInitiator={isInitiator}
                contracts={contracts}
              />
            ))
          : Object.keys(groups).map((el) => (
              <GroupsItem
                isInitiator={isInitiator}
                groupData={groups[el]}
                key={el}
                contracts={contracts}
              />
            ))}
      </div>
      {isModalOpen && (
        <HandleUserModal contracts={contracts} handleOpen={handleModal} />
      )}
    </>
  );
};

export default Management;
