import React from "react";
import check from "../../assets/svg/checked.svg";

const ApprovedMark = ({ className = '', ...restProps }) => (
		<span className={`tx-approved ${className}`} {...restProps} >
			<img src={check} alt="check"/>
			Approved
		</span>
)

export default ApprovedMark;
