import getContractInfo from "./getContractInfo";
import parseInput from "./parseInput";

export default function formatTransactions(
  transactions,
  address,
  groups,
  contracts,
) {
  return transactions
    .filter((transaction) => transaction.confirmations.length > 0)
    .map((transaction) => {
      const {
        multisigAddress,
        txId,
        parsedCalldata,
        executed,
        value,
        confirmations,
      } = transaction;

      return {
        txId,
        threshold: groups[multisigAddress].threshold,
        hasUserConfirmed: transaction.confirmations.includes(address),
        multisigAddress,
        confirmations,
        executed,
        value,
        ...getContractInfo(contracts, multisigAddress),
        ...(parsedCalldata && {
          parsedCalldata,
          method: parsedCalldata.name,
          additionalInfo: parseInput(
            parsedCalldata.name,
            parsedCalldata.inputs,
            contracts,
          ),
        }),
      };
    });
}
