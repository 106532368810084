import { ContractNames } from "@airdao/airdao-node-contracts";
import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { astraMethods } from "../../../../utils/constants";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";

const Astra = () => {
  const { contracts } = useContracts();

  return (
    <>
      <ContractMethodsForm
        label={ContractNames.Ecosystem_AstradexMultisig}
        methods={astraMethods}
        contract={contracts.getContractByName(
          ContractNames.Ecosystem_AstradexMultisig,
        )}
        multisig={contracts.getContractByName(
          ContractNames.Ecosystem_AstradexMultisig,
        )}
      />
    </>
  );
};

export default Astra;
