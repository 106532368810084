export default {
  16718: {
    OrbitOracle: {
      address: "0x961cca7b973a6537ef0bab7fd52a937e4fb5cc5e",
      abi: [
        {
          type: "constructor",
          inputs: [
            {
              name: "initialSupply",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "backendAddress",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "getCirculationSupply",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "owner",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "renounceOwnership",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "setBackendAddress",
          inputs: [
            {
              name: "newAddr",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "transferOwnership",
          inputs: [
            {
              name: "newOwner",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "updateCirculationSupply",
          inputs: [
            {
              name: "newSupply",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "event",
          name: "OwnershipTransferred",
          inputs: [
            {
              name: "previousOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "newOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
      ],
      inheritedFunctions: {
        owner: "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        renounceOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        transferOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        getCirculationSupply: "contracts/interfaces/IOrbitOracle.sol",
        setBackendAddress: "contracts/interfaces/IOrbitOracle.sol",
        updateCirculationSupply: "contracts/interfaces/IOrbitOracle.sol",
      },
    },
    OrbitLend: {
      address: "0xdd7a6dc26a2d25016600936fb5a1dcb2612c4add",
      abi: [
        {
          type: "constructor",
          inputs: [
            {
              name: "_underlying",
              type: "address",
              internalType: "contract IERC20Metadata",
            },
            {
              name: "_oracle",
              type: "address",
              internalType: "contract IOrbitOracle",
            },
            {
              name: "_leverageFactor",
              type: "uint48",
              internalType: "uint48",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "MAX_PERCENT",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "allowance",
          inputs: [
            {
              name: "owner",
              type: "address",
              internalType: "address",
            },
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "approve",
          inputs: [
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "balanceOf",
          inputs: [
            {
              name: "account",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "borrow",
          inputs: [
            {
              name: "amountIn",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "tokenOut",
              type: "address",
              internalType: "contract IERC20",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "decimals",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint8",
              internalType: "uint8",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "decreaseAllowance",
          inputs: [
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
            {
              name: "subtractedValue",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "deployTreasury",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "fundTreasury",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "contract IERC20",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "getAvailableBorrow",
          inputs: [
            {
              name: "tokenOut",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "getBorrowAmountIn",
          inputs: [
            {
              name: "amountOut",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "tokenOut",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "amountIn",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "getUserDebt",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "token",
              type: "address",
              internalType: "contract IERC20",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "increaseAllowance",
          inputs: [
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
            {
              name: "addedValue",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "leverageFactor",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "name",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "string",
              internalType: "string",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "oracle",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "contract IOrbitOracle",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "owner",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "pause",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "paused",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "renounceOwnership",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "repay",
          inputs: [
            {
              name: "amountIn",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "tokenIn",
              type: "address",
              internalType: "contract IERC20",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "setLeverageFactor",
          inputs: [
            {
              name: "_leverageFactor",
              type: "uint48",
              internalType: "uint48",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "setOracle",
          inputs: [
            {
              name: "newOracle",
              type: "address",
              internalType: "contract IOrbitOracle",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "stakeUnderlyingToken",
          inputs: [
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "symbol",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "string",
              internalType: "string",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "tokenTreasuries",
          inputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "contract ITreasury",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "totalSupply",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "transfer",
          inputs: [
            {
              name: "to",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "transferFrom",
          inputs: [
            {
              name: "from",
              type: "address",
              internalType: "address",
            },
            {
              name: "to",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "transferOwnership",
          inputs: [
            {
              name: "newOwner",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "underlying",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "contract IERC20Metadata",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "underlyingCirculationSupply",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "unpause",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "unstakeUnderlyingToken",
          inputs: [
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "withdrawTreasury",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "contract IERC20",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "to",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "event",
          name: "Approval",
          inputs: [
            {
              name: "owner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "spender",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "value",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Borrowed",
          inputs: [
            {
              name: "user",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amountIn",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "amountOut",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "OwnershipTransferred",
          inputs: [
            {
              name: "previousOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "newOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Paused",
          inputs: [
            {
              name: "account",
              type: "address",
              indexed: false,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Repaid",
          inputs: [
            {
              name: "user",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amountIn",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "amountOut",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "StakeChanged",
          inputs: [
            {
              name: "user",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amount",
              type: "int256",
              indexed: false,
              internalType: "int256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Transfer",
          inputs: [
            {
              name: "from",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "to",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "value",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "TreasuryDeployed",
          inputs: [
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "TreasuryFunded",
          inputs: [
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "TreasuryWithdrawn",
          inputs: [
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "to",
              type: "address",
              indexed: false,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Unpaused",
          inputs: [
            {
              name: "account",
              type: "address",
              indexed: false,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "error",
          name: "AmountMustBeGreaterThanZero",
          inputs: [],
        },
        {
          type: "error",
          name: "InsufficientStakedHBRBalance",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
        {
          type: "error",
          name: "OracleNotSet",
          inputs: [],
        },
        {
          type: "error",
          name: "StakeFailed",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
        {
          type: "error",
          name: "TransferFailed",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "token",
              type: "address",
              internalType: "address",
            },
          ],
        },
        {
          type: "error",
          name: "TreasuryNotDeployed",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "address",
            },
          ],
        },
        {
          type: "error",
          name: "UnstakeFailed",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
      ],
      inheritedFunctions: {
        allowance: "contracts/CollateralToken.sol",
        approve: "contracts/CollateralToken.sol",
        balanceOf: "contracts/CollateralToken.sol",
        decimals: "contracts/CollateralToken.sol",
        decreaseAllowance: "contracts/CollateralToken.sol",
        increaseAllowance: "contracts/CollateralToken.sol",
        name: "contracts/CollateralToken.sol",
        symbol: "contracts/CollateralToken.sol",
        totalSupply: "contracts/CollateralToken.sol",
        transfer: "contracts/CollateralToken.sol",
        transferFrom: "contracts/CollateralToken.sol",
        underlying: "contracts/CollateralToken.sol",
        owner: "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        renounceOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        transferOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        paused:
          "dependencies/@openzeppelin-contracts-4.9.6/security/Pausable.sol",
        borrow: "contracts/interfaces/IOrbitLend.sol",
        deployTreasury: "contracts/interfaces/IOrbitLend.sol",
        fundTreasury: "contracts/interfaces/IOrbitLend.sol",
        getAvailableBorrow: "contracts/interfaces/IOrbitLend.sol",
        getUserDebt: "contracts/interfaces/IOrbitLend.sol",
        repay: "contracts/interfaces/IOrbitLend.sol",
        stakeUnderlyingToken: "contracts/interfaces/IOrbitLend.sol",
        underlyingCirculationSupply: "contracts/interfaces/IOrbitLend.sol",
        unstakeUnderlyingToken: "contracts/interfaces/IOrbitLend.sol",
      },
    },
  },
  22040: {
    OrbitOracle: {
      address: "0x0e57cc508ea809ba4ad8fa4b91f01ff84932b139",
      abi: [
        {
          type: "constructor",
          inputs: [
            {
              name: "initialSupply",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "backendAddress",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "getCirculationSupply",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "owner",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "renounceOwnership",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "setBackendAddress",
          inputs: [
            {
              name: "newAddr",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "transferOwnership",
          inputs: [
            {
              name: "newOwner",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "updateCirculationSupply",
          inputs: [
            {
              name: "newSupply",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "event",
          name: "OwnershipTransferred",
          inputs: [
            {
              name: "previousOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "newOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
      ],
      inheritedFunctions: {
        owner: "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        renounceOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        transferOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        getCirculationSupply: "contracts/interfaces/IOrbitOracle.sol",
        setBackendAddress: "contracts/interfaces/IOrbitOracle.sol",
        updateCirculationSupply: "contracts/interfaces/IOrbitOracle.sol",
      },
    },
    OrbitLend: {
      address: "0x109948270c9be75020bf74538d1c3db17d6c5efd",
      abi: [
        {
          type: "constructor",
          inputs: [
            {
              name: "_underlying",
              type: "address",
              internalType: "contract IERC20Metadata",
            },
            {
              name: "_oracle",
              type: "address",
              internalType: "contract IOrbitOracle",
            },
            {
              name: "_leverageFactor",
              type: "uint48",
              internalType: "uint48",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "MAX_PERCENT",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "allowance",
          inputs: [
            {
              name: "owner",
              type: "address",
              internalType: "address",
            },
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "approve",
          inputs: [
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "balanceOf",
          inputs: [
            {
              name: "account",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "borrow",
          inputs: [
            {
              name: "amountIn",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "tokenOut",
              type: "address",
              internalType: "contract IERC20",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "decimals",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint8",
              internalType: "uint8",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "decreaseAllowance",
          inputs: [
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
            {
              name: "subtractedValue",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "deployTreasury",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "fundTreasury",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "contract IERC20",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "getAvailableBorrow",
          inputs: [
            {
              name: "tokenOut",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "getBorrowAmountIn",
          inputs: [
            {
              name: "amountOut",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "tokenOut",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "amountIn",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "getUserDebt",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "token",
              type: "address",
              internalType: "contract IERC20",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "increaseAllowance",
          inputs: [
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
            {
              name: "addedValue",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "leverageFactor",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "name",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "string",
              internalType: "string",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "oracle",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "contract IOrbitOracle",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "owner",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "pause",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "paused",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "renounceOwnership",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "repay",
          inputs: [
            {
              name: "amountIn",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "tokenIn",
              type: "address",
              internalType: "contract IERC20",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "setLeverageFactor",
          inputs: [
            {
              name: "_leverageFactor",
              type: "uint48",
              internalType: "uint48",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "setOracle",
          inputs: [
            {
              name: "newOracle",
              type: "address",
              internalType: "contract IOrbitOracle",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "stakeUnderlyingToken",
          inputs: [
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "symbol",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "string",
              internalType: "string",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "tokenTreasuries",
          inputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "contract ITreasury",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "totalSupply",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "transfer",
          inputs: [
            {
              name: "to",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "transferFrom",
          inputs: [
            {
              name: "from",
              type: "address",
              internalType: "address",
            },
            {
              name: "to",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "transferOwnership",
          inputs: [
            {
              name: "newOwner",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "underlying",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "contract IERC20Metadata",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "underlyingCirculationSupply",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "unpause",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "unstakeUnderlyingToken",
          inputs: [
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "withdrawTreasury",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "contract IERC20",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "to",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "event",
          name: "Approval",
          inputs: [
            {
              name: "owner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "spender",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "value",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Borrowed",
          inputs: [
            {
              name: "user",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amountIn",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "amountOut",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "OwnershipTransferred",
          inputs: [
            {
              name: "previousOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "newOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Paused",
          inputs: [
            {
              name: "account",
              type: "address",
              indexed: false,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Repaid",
          inputs: [
            {
              name: "user",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amountIn",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "amountOut",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "StakeChanged",
          inputs: [
            {
              name: "user",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amount",
              type: "int256",
              indexed: false,
              internalType: "int256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Transfer",
          inputs: [
            {
              name: "from",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "to",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "value",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "TreasuryDeployed",
          inputs: [
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "TreasuryFunded",
          inputs: [
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "TreasuryWithdrawn",
          inputs: [
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "to",
              type: "address",
              indexed: false,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Unpaused",
          inputs: [
            {
              name: "account",
              type: "address",
              indexed: false,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "error",
          name: "AmountMustBeGreaterThanZero",
          inputs: [],
        },
        {
          type: "error",
          name: "InsufficientStakedHBRBalance",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
        {
          type: "error",
          name: "OracleNotSet",
          inputs: [],
        },
        {
          type: "error",
          name: "StakeFailed",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
        {
          type: "error",
          name: "TransferFailed",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "token",
              type: "address",
              internalType: "address",
            },
          ],
        },
        {
          type: "error",
          name: "TreasuryNotDeployed",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "address",
            },
          ],
        },
        {
          type: "error",
          name: "UnstakeFailed",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
      ],
      inheritedFunctions: {
        allowance: "contracts/CollateralToken.sol",
        approve: "contracts/CollateralToken.sol",
        balanceOf: "contracts/CollateralToken.sol",
        decimals: "contracts/CollateralToken.sol",
        decreaseAllowance: "contracts/CollateralToken.sol",
        increaseAllowance: "contracts/CollateralToken.sol",
        name: "contracts/CollateralToken.sol",
        symbol: "contracts/CollateralToken.sol",
        totalSupply: "contracts/CollateralToken.sol",
        transfer: "contracts/CollateralToken.sol",
        transferFrom: "contracts/CollateralToken.sol",
        underlying: "contracts/CollateralToken.sol",
        owner: "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        renounceOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        transferOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        paused:
          "dependencies/@openzeppelin-contracts-4.9.6/security/Pausable.sol",
        borrow: "contracts/interfaces/IOrbitLend.sol",
        deployTreasury: "contracts/interfaces/IOrbitLend.sol",
        fundTreasury: "contracts/interfaces/IOrbitLend.sol",
        getAvailableBorrow: "contracts/interfaces/IOrbitLend.sol",
        getUserDebt: "contracts/interfaces/IOrbitLend.sol",
        repay: "contracts/interfaces/IOrbitLend.sol",
        stakeUnderlyingToken: "contracts/interfaces/IOrbitLend.sol",
        underlyingCirculationSupply: "contracts/interfaces/IOrbitLend.sol",
        unstakeUnderlyingToken: "contracts/interfaces/IOrbitLend.sol",
      },
    },
  },
  30746: {
    OrbitOracle: {
      address: "0x0aa1d5b43b8dd696622f626023bb06cb23e23f7b",
      abi: [
        {
          type: "constructor",
          inputs: [
            {
              name: "initialSupply",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "backendAddress",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "getCirculationSupply",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "owner",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "renounceOwnership",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "setBackendAddress",
          inputs: [
            {
              name: "newAddr",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "transferOwnership",
          inputs: [
            {
              name: "newOwner",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "updateCirculationSupply",
          inputs: [
            {
              name: "newSupply",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "event",
          name: "OwnershipTransferred",
          inputs: [
            {
              name: "previousOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "newOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
      ],
      inheritedFunctions: {
        owner: "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        renounceOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        transferOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        getCirculationSupply: "contracts/interfaces/IOrbitOracle.sol",
        setBackendAddress: "contracts/interfaces/IOrbitOracle.sol",
        updateCirculationSupply: "contracts/interfaces/IOrbitOracle.sol",
      },
    },
    OrbitLend: {
      address: "0x0b19ba859c1c6059d7f93520a14195fc10897d9c",
      abi: [
        {
          type: "constructor",
          inputs: [
            {
              name: "_underlying",
              type: "address",
              internalType: "contract IERC20Metadata",
            },
            {
              name: "_oracle",
              type: "address",
              internalType: "contract IOrbitOracle",
            },
            {
              name: "_leverageFactor",
              type: "uint48",
              internalType: "uint48",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "MAX_PERCENT",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "allowance",
          inputs: [
            {
              name: "owner",
              type: "address",
              internalType: "address",
            },
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "approve",
          inputs: [
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "balanceOf",
          inputs: [
            {
              name: "account",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "borrow",
          inputs: [
            {
              name: "amountIn",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "tokenOut",
              type: "address",
              internalType: "contract IERC20",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "decimals",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint8",
              internalType: "uint8",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "decreaseAllowance",
          inputs: [
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
            {
              name: "subtractedValue",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "deployTreasury",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "fundTreasury",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "contract IERC20",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "getAvailableBorrow",
          inputs: [
            {
              name: "tokenOut",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "getBorrowAmountIn",
          inputs: [
            {
              name: "amountOut",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "tokenOut",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "amountIn",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "getUserDebt",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "token",
              type: "address",
              internalType: "contract IERC20",
            },
          ],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "increaseAllowance",
          inputs: [
            {
              name: "spender",
              type: "address",
              internalType: "address",
            },
            {
              name: "addedValue",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "leverageFactor",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "name",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "string",
              internalType: "string",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "oracle",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "contract IOrbitOracle",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "owner",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "pause",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "paused",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "renounceOwnership",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "repay",
          inputs: [
            {
              name: "amountIn",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "tokenIn",
              type: "address",
              internalType: "contract IERC20",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "setLeverageFactor",
          inputs: [
            {
              name: "_leverageFactor",
              type: "uint48",
              internalType: "uint48",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "setOracle",
          inputs: [
            {
              name: "newOracle",
              type: "address",
              internalType: "contract IOrbitOracle",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "stakeUnderlyingToken",
          inputs: [
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "symbol",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "string",
              internalType: "string",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "tokenTreasuries",
          inputs: [
            {
              name: "",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "contract ITreasury",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "totalSupply",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "transfer",
          inputs: [
            {
              name: "to",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "transferFrom",
          inputs: [
            {
              name: "from",
              type: "address",
              internalType: "address",
            },
            {
              name: "to",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [
            {
              name: "",
              type: "bool",
              internalType: "bool",
            },
          ],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "transferOwnership",
          inputs: [
            {
              name: "newOwner",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "underlying",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "address",
              internalType: "contract IERC20Metadata",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "underlyingCirculationSupply",
          inputs: [],
          outputs: [
            {
              name: "",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          stateMutability: "view",
        },
        {
          type: "function",
          name: "unpause",
          inputs: [],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "unstakeUnderlyingToken",
          inputs: [
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "function",
          name: "withdrawTreasury",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "contract IERC20",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "to",
              type: "address",
              internalType: "address",
            },
          ],
          outputs: [],
          stateMutability: "nonpayable",
        },
        {
          type: "event",
          name: "Approval",
          inputs: [
            {
              name: "owner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "spender",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "value",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Borrowed",
          inputs: [
            {
              name: "user",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amountIn",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "amountOut",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "OwnershipTransferred",
          inputs: [
            {
              name: "previousOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "newOwner",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Paused",
          inputs: [
            {
              name: "account",
              type: "address",
              indexed: false,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Repaid",
          inputs: [
            {
              name: "user",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amountIn",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "amountOut",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "StakeChanged",
          inputs: [
            {
              name: "user",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amount",
              type: "int256",
              indexed: false,
              internalType: "int256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Transfer",
          inputs: [
            {
              name: "from",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "to",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "value",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "TreasuryDeployed",
          inputs: [
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "TreasuryFunded",
          inputs: [
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "TreasuryWithdrawn",
          inputs: [
            {
              name: "token",
              type: "address",
              indexed: true,
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              indexed: false,
              internalType: "uint256",
            },
            {
              name: "to",
              type: "address",
              indexed: false,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "event",
          name: "Unpaused",
          inputs: [
            {
              name: "account",
              type: "address",
              indexed: false,
              internalType: "address",
            },
          ],
          anonymous: false,
        },
        {
          type: "error",
          name: "AmountMustBeGreaterThanZero",
          inputs: [],
        },
        {
          type: "error",
          name: "InsufficientStakedHBRBalance",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
        {
          type: "error",
          name: "OracleNotSet",
          inputs: [],
        },
        {
          type: "error",
          name: "StakeFailed",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
        {
          type: "error",
          name: "TransferFailed",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
            {
              name: "token",
              type: "address",
              internalType: "address",
            },
          ],
        },
        {
          type: "error",
          name: "TreasuryNotDeployed",
          inputs: [
            {
              name: "token",
              type: "address",
              internalType: "address",
            },
          ],
        },
        {
          type: "error",
          name: "UnstakeFailed",
          inputs: [
            {
              name: "user",
              type: "address",
              internalType: "address",
            },
            {
              name: "amount",
              type: "uint256",
              internalType: "uint256",
            },
          ],
        },
      ],
      inheritedFunctions: {
        allowance: "contracts/CollateralToken.sol",
        approve: "contracts/CollateralToken.sol",
        balanceOf: "contracts/CollateralToken.sol",
        decimals: "contracts/CollateralToken.sol",
        decreaseAllowance: "contracts/CollateralToken.sol",
        increaseAllowance: "contracts/CollateralToken.sol",
        name: "contracts/CollateralToken.sol",
        symbol: "contracts/CollateralToken.sol",
        totalSupply: "contracts/CollateralToken.sol",
        transfer: "contracts/CollateralToken.sol",
        transferFrom: "contracts/CollateralToken.sol",
        underlying: "contracts/CollateralToken.sol",
        owner: "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        renounceOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        transferOwnership:
          "dependencies/@openzeppelin-contracts-4.9.6/access/Ownable.sol",
        paused:
          "dependencies/@openzeppelin-contracts-4.9.6/security/Pausable.sol",
        borrow: "contracts/interfaces/IOrbitLend.sol",
        deployTreasury: "contracts/interfaces/IOrbitLend.sol",
        fundTreasury: "contracts/interfaces/IOrbitLend.sol",
        getAvailableBorrow: "contracts/interfaces/IOrbitLend.sol",
        getUserDebt: "contracts/interfaces/IOrbitLend.sol",
        repay: "contracts/interfaces/IOrbitLend.sol",
        stakeUnderlyingToken: "contracts/interfaces/IOrbitLend.sol",
        underlyingCirculationSupply: "contracts/interfaces/IOrbitLend.sol",
        unstakeUnderlyingToken: "contracts/interfaces/IOrbitLend.sol",
      },
    },
  },
};
