/*
Copyright: Ambrosus Inc.
Email: tech@ambrosus.io

This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0. If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.

This Source Code Form is “Incompatible With Secondary Licenses”, as defined by the Mozilla Public License, v. 2.0.
*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/svg/logo.svg";
import GroupItem from "./GroupItem";
import useUser from "../../contexts/UserPermissionsContext/useUser";
import { getContractLabel } from "../../utils/contractLabels";
import { ContractNames } from "@airdao/airdao-node-contracts";
import useContracts from "../../contexts/ContractsContext/useContracts";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ASTRA_MENU,
  BANKS_MENU,
  CORE_MENU,
  KOSMOS_MENU,
  LIQUID_STAKING_MENU,
  STAKING_MENU,
} from "../../utils/constants";

const Menu = () => {
  const { contracts, chainId } = useContracts();
  const { permissions, address } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const [menu, setMenu] = useState({
    kosmos: [],
    staking: [],
    core: [],
    astra: [],
    liquid: [],
    banks: [],
    hasAnyAccess: false,
  });

  useEffect(() => {
    const userNamedPermissions = permissions.map((el) =>
      contracts.getNameByAddressSafe(el.address),
    );

    function getMenu(menuContracts) {
      return menuContracts
        .filter(({ contractName }) =>
          userNamedPermissions.includes(contractName),
        )
        .map(({ contractName, url, label }) => ({
          text: label || getContractLabel(contractName),
          value: contractName,
          url,
        }));
    }

    // contract name | permission contract name
    const kosmos = getMenu(KOSMOS_MENU);
    const core = getMenu(CORE_MENU);
    const staking = getMenu(STAKING_MENU);
    const astra = getMenu(ASTRA_MENU);
    const liquid = getMenu(LIQUID_STAKING_MENU);
    const banks = getMenu(BANKS_MENU);

    const hasAnyAccess = permissions.length > 0;

    setMenu({ staking, kosmos, core, astra, liquid, hasAnyAccess, banks });

    const allPages = [
      ...staking,
      ...kosmos,
      ...core,
      ...astra,
      ...liquid,
      ...banks,
      { url: "/confirmation" },
    ];
    const isPageAllowed = allPages.some((el) => el.url === location.pathname);

    if (!isPageAllowed) navigate("/");
  }, [permissions, chainId]);

  const networkUrl =
    {
      16718: "https://network.ambrosus.io",
      22040: "https://network.ambrosus-test.io",
      30746: "https://network.ambrosus-dev.io",
    }[window.ethereum.networkVersion] || "Unknown network";

  return (
    <div className="nav-menu">
      <img src={logo} alt="airdao" className="nav-menu__logo" />
      <p className="nav-menu__label">Network</p>
      <p className="nav-menu__link">{networkUrl}</p>
      <p className="nav-menu__label">Account</p>
      <p className="nav-menu__address">
        {address.slice(0, 10) + "..." + address.slice(-10)}
      </p>
      <p
        className={`nav-menu__access ${
          menu.hasAnyAccess ? "nav-menu__access_true" : ""
        }`}
      >
        {menu.hasAnyAccess ? "Access" : "No access"}
      </p>

      <p className="nav-menu__options-label">Select the multisig option</p>
      <ul className="nav-menu__groups">
        {menu.hasAnyAccess && (
          <GroupItem
            name="Confirmations"
            options={[]}
            redirectTo="/confirmation"
          />
        )}
        {!!menu.kosmos.length && (
          <GroupItem name="Kosmos" options={menu.kosmos} />
        )}
        {!!menu.staking.length && (
          <GroupItem name="Staking" options={[]} redirectTo="/staking" />
        )}
        {!!menu.astra.length && (
          <GroupItem name="Astra DEX" options={menu.astra} />
        )}
        {!!menu.liquid.length && (
          <GroupItem name="Harbor" options={menu.liquid} />
        )}
        {!!menu.core.length && (
          <GroupItem name="Core settings" options={menu.core} />
        )}
        {!!menu.banks.length && (
          <GroupItem name="Banks" options={[]} redirectTo="/banks" />
        )}
      </ul>
      <p></p>
    </div>
  );
};

Menu.propTypes = {
  address: PropTypes.string,
  hasAccess: PropTypes.bool,
};

export default Menu;
