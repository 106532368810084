import React, {useEffect, useState} from 'react';
import Modal from "../../UI/Modal";
import arrow from "../../../assets/svg/arrow.svg";
import ApprovedMark from "../../UI/ApprovedMark";
import {Multisig} from "@airdao/airdao-node-contracts";
import InlineLoader from "../../UI/InlineLoader";
import {getContractLabel} from "../../../utils/contractLabels";

const GroupsItem = ({ groupData, contracts, isInitiator }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [percent, setPercent] = useState('');
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
    setResult(null);
    setPercent('');
  }, [isEditOpen]);

  const multisigName = contracts.getNameByAddress(groupData.multisig);

  const handlePercent = ({ target }) => {
    if (/^(\s*|\d+)$/.test(target.value) && !(!percent && target.value === '0')) {
      setPercent(target.value);
      setError('');
    }
  };

  const handleOpen = () => setIsEditOpen((state) => !state);

  const handleSubmit = async () => {
    Multisig.setThreshold(contracts, multisigName, percent)
      .then((res) => {
        setIsLoading(true);
        res.wait()
          .then((res) => setResult(res))
          .finally(() => setIsLoading(false));
      })
      .catch((e) => {
        if (e.message.length < 100) {
          setError(e.message)
        } else {
          console.log(e.message)
        }
      });
  };

  return (
    <div className='user-list-item'>
      <span>{getContractLabel(multisigName)}</span>
      {isInitiator && (
        <button className='user-list-item__btn' onClick={handleOpen}>
          Edit
        </button>
      )}
      {isEditOpen && (
        <Modal title={getContractLabel(multisigName)} handleClose={handleOpen}>
          <span className="modal-label">Current % confirms</span>
          <input type="text" className="ui-input" value={groupData.thresholdPercent} disabled />
          <span className="modal-label">Change % confirms</span>
          <input
            className="ui-input"
            value={percent}
            onChange={handlePercent}
            placeholder='0'
          />
          <div style={{ marginTop: 32 }} className="modal__actions-bottom">
            <button onClick={handleSubmit} type="button" className="ui-button">
              Submit
              <img src={arrow} alt="arrow"/>
            </button>
            {isLoading && <InlineLoader />}
            {(!!result || error) && (
              error ? (
                <span className='error-message'>{error}</span>
              ) : (
                <ApprovedMark />
              )
            )}
          </div>
          <span className="modal-label">List of users</span>
          {groupData.users.map((el) => (
            <p className="group-users" key={el.address}>
              {el.address}
              {el.isInitiator && <span className="group-users__initiator">Initiator</span>}
            </p>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default GroupsItem;
