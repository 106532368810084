import { useCallback, useEffect, useState } from "react";
import ContractsContext from "./context";
import { Multisig } from "@airdao/airdao-node-contracts";
import useContracts from "../ContractsContext/useContracts";

export default function PermissionsContextProvider(props) {
  const [permissions, setPermissions] = useState({ users: [], groups: [] });
  const [address, setAddress] = useState();
  const { contracts, chainId } = useContracts();

  useEffect(() => {
    const { ethereum } = window;
    if (!ethereum) return;

    ethereum.on("accountsChanged", (address) => {
      setAddress(address);
    });
  }, []);

  useEffect(() => {
    handlePermissions();
  }, [address, contracts, chainId]);

  const handlePermissions = useCallback(async () => {
    if (!contracts) return;
    const { users, groups } = await Multisig.getPermissions(contracts);
    setPermissions({ users, groups });
  }, [contracts, chainId]);

  return <ContractsContext.Provider value={permissions} {...props} />;
}
