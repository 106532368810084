import useContracts from "../../../../../contexts/ContractsContext/useContracts";
import ContractMethodsForm from "../../../../UI/ContractMethodsForm";
import { Form } from "../../../../UI/Form";
import { Input } from "../../../../UI/Inputs";
import { useEffect, useState } from "react";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { utils } from "ethers";
import {
  liquidNodesManagerMethods,
  liquidStakingTreasuryMethods,
} from "../../../../../utils/constants";

const NodesQuantity = () => {
  const { contracts } = useContracts();

  const contract = contracts.getContractByName(
    ContractNames.Ecosystem_LiquidNodesManager,
  );

  const [currentMaxCount, setCurrentMaxCount] = useState("");
  const [nodeStake, setNodeStake] = useState("");
  useEffect(() => {
    contract
      .maxNodesCount()
      .then((res) => setCurrentMaxCount(utils.formatUnits(res, 0)));

    contract.nodeStake().then((res) => setNodeStake(utils.formatEther(res)));
  }, []);

  return (
    <ContractMethodsForm
      methods={liquidNodesManagerMethods}
      contract={contract}
      multisig={ContractNames.Ecosystem_LiquidPoolMultisig}
      label="Nodes quantity"
    >
      <div>
        <p>
          Current node stake: <b>{nodeStake} AMB</b>
        </p>
        <p>
          Current max count: <b>{currentMaxCount}</b>
        </p>
      </div>
    </ContractMethodsForm>
  );
};

export default NodesQuantity;
