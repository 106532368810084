export default function TransactionDetails({
	parsedCalldata,
	...props
}) {
		return (
			<div className='transaction-item__arguments'>
				{ Object.keys(props).map((key) => (
						<div className={'transaction-item_argument-item'}>
						<span className={'transaction-item__argument-name'}>
							{key}:
						</span>
							{' '}
						<span className={'transaction-item__argument-value'}>
							{JSON.stringify(props[key], null, 2)}
						</span>

						</div>
				)) }
			</div>
		)
}
