import { useEffect, useMemo, useState } from "react";
import {
  AmbErrorProviderWeb3,
  ContractNames,
  Contracts,
  MultisigVersions,
} from "@airdao/airdao-node-contracts";
import { Contracts as BondContracts } from "@airdao/airdao-bond";
import { Contracts as PeripheryContracts } from "@airdao/bond-periphery";
import ContractsContext from "./context";
import useGetBalanceByContract from "../../lib/hooks/use-get-balance-by-contract";
import { ethers } from "ethers";
const ambChainId = 16718;

export default function ContractsContextProvider(props) {
  const [contracts, setContracts] = useState(null);
  const [bondContracts, setBondContracts] = useState(null);
  const [peripheryContracts, setPeripheryContracts] = useState(null);
  const [provider, setProvider] = useState(null);
  const [chainId, setChainId] = useState(0);

  const { feeBalances, isLoading, setAddress, address, getBalances } =
    useGetBalanceByContract(chainId);

  useEffect(() => {
    const { ethereum } = window;
    if (ethereum) {
      setProvider(new AmbErrorProviderWeb3(ethereum));
    }
  }, [chainId]);

  useEffect(() => {
    (async () => {
      const { ethereum } = window;
      if (!ethereum || !provider) return;
      ethereum.enable();

      const chainId = (await provider.getNetwork()).chainId;

      // if (chainId && chainId.toString() !== ambChainId.toString()) {
      //   provider.provider.request({
      //     method: "wallet_switchEthereumChain",
      //     params: [{ chainId: ethers.utils.hexValue(ambChainId) }],
      //   });
      // }
      setChainId(chainId);

      ethereum.on("networkChanged", (networkId) => {
        setChainId(networkId);
      });
    })();
  }, [provider, chainId]);

  useEffect(() => {
    (async () => {
      if (!chainId || !provider) return;
      const signer = provider.getSigner();

      setContracts(new Contracts(signer, chainId, MultisigVersions.ecosystem));
      setBondContracts(new BondContracts(signer, chainId));
      setPeripheryContracts(new PeripheryContracts(signer, chainId));
    })();
  }, [provider, chainId]);

  const value = useMemo(
    () => ({
      contracts,
      bondContracts,
      feeBalances,
      address,
      setAddress,
      getBalances,
      isLoading,
      peripheryContracts,
      provider,
      chainId,
    }),
    [
      contracts,
      setAddress,
      bondContracts,
      feeBalances,
      address,
      getBalances,
      isLoading,
      peripheryContracts,
      provider,
      chainId,
    ],
  );

  return <ContractsContext.Provider value={value} {...props} />;
}
