import { ethers, utils } from "ethers";
import mathModuleAbi from "../../../../utils/abi/astraMathModule";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { astraEclipseMethods } from "../../../../utils/constants";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import { useEffect, useState } from "react";
import useContracts from "../../../../contexts/ContractsContext/useContracts";

const Math = ({ contract }) => {
  const { provider } = useContracts();

  const [mathModuleContract, setMathModuleContract] = useState(null);
  const [methods, setMethods] = useState([]);
  const [decimals, setDecimals] = useState(0);
  const [defaultPercent, setDefaultPercent] = useState("");
  const [percentsList, setPercentsList] = useState([]);

  useEffect(() => {
    contract
      .mathModule()
      .then((res) =>
        setMathModuleContract(
          new ethers.Contract(res, mathModuleAbi, provider.getSigner()),
        ),
      );
  }, []);

  useEffect(() => {
    if (mathModuleContract) {
      mathModuleContract.DECIMALS().then((res) => {
        setDecimals(res);
        setMethods([
          `setDefaultPremiumPercent(uint48*decimals${res - 2}:Percent %)`,
          `setPremiumPercent(uint48*decimals${res - 2}:Percent %,uint256:Pool lifetime in seconds)`,
        ]);
      });
      setPercentsList([]);
      mathModuleContract.premiumsLength().then(async (res) => {
        for (let i = 0; i < utils.formatUnits(res, 0); i++) {
          const data = await mathModuleContract.premiumPercents(i);
          setPercentsList((state) => [...state, data]);
        }
      });
    }
  }, [mathModuleContract]);

  useEffect(() => {
    if (decimals && mathModuleContract) {
      mathModuleContract.DEFAULT_PREMIUM_PERCENT().then((res) => {
        setDefaultPercent(utils.formatUnits(res.toString(), decimals));
      });
    }
  }, [decimals, mathModuleContract]);

  return (
    <ContractMethodsForm
      multisig={ContractNames.Ecosystem_AstradexMultisig}
      contract={contract}
      methods={methods}
      label="Math"
    >
      <div>
        <p>
          Current default premium percent: <b>{defaultPercent * 100}%</b>
        </p>
        <p>Premium percent list:</p>
        <ul>
          {percentsList.map((el, index) => (
            <li>
              {index + 1}: Percent -{" "}
              <b>{utils.formatUnits(el.percent, decimals - 2)}%</b>, Lifetime -{" "}
              <b>{+utils.formatUnits(el.poolLifetime, 0) / 60} minutes</b>
            </li>
          ))}
        </ul>
      </div>
    </ContractMethodsForm>
  );
};

export default Math;
