import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { useRef } from "react";
import { ethers } from "ethers";
import abi from "../../../../utils/abi/astra";
import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { astraTokenMethods } from "../../../../utils/constants";

const Token = () => {
  const { chainId, provider } = useContracts();

  const contract = useRef(
    new ethers.Contract(
      abi[chainId].AstraToken.address,
      abi[chainId].AstraToken.abi,
      provider.getSigner(),
    ),
  );

  return (
    <ContractMethodsForm
      multisig={ContractNames.Ecosystem_AstradexMultisig}
      contract={contract.current}
      methods={astraTokenMethods}
      label="Astra token"
    />
  );
};

export default Token;
