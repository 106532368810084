import { Form } from "./Form";
import { Input } from "./Inputs";
import { useEffect, useMemo, useState } from "react";
import UiCheckbox from "./UiCheckbox";
import useContracts from "../../contexts/ContractsContext/useContracts";
import { ContractNames } from "@airdao/bond-periphery";
import { ContractNames as MainContractNames } from "@airdao/airdao-node-contracts";
import { submitTransaction } from "../../utils/submitTransaction";
import { ethers, utils } from "ethers";

const parseMethodSignature = (signature) => {
  const methodRegex = /(\w+)\((.*)\)/;
  const match = signature.match(methodRegex);
  if (!match) return { methodName: "", params: [] };

  const methodName = match[1];
  const params = match[2]
    .split(",")
    .map((param) => ({ type: param, isArray: param.includes("[") }));

  return { methodName, params };
};

const MethodParamsForm = ({ methodSignature, contract, multisig }) => {
  const { contracts, peripheryContracts } = useContracts();

  const [formData, setFormData] = useState({});

  useEffect(() => {
    setDefaultValues();
  }, []);

  const { methodName, params } = useMemo(() => {
    return parseMethodSignature(methodSignature);
  }, [methodSignature]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setDefaultValues = () => {
    params.forEach((el, index) => {
      if (el.type.includes(":kos")) {
        const name = `index${index}`;
        setFormData((state) => ({
          ...state,
          [name]: peripheryContracts.getContractByName(
            ContractNames.StakingToken,
          ).address,
        }));
      } else if (el.type.includes("=")) {
        let value = el.type.split("=")[1];
        if (value.includes(":")) {
          value = value.split(":")[0];
        }

        const name = `index${index}`;
        setFormData((state) => ({
          ...state,
          [name]: value,
        }));
      }
    });
  };

  const handleBool = (name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const parseParams = (data) =>
    Object.keys(data)
      .map((el) => {
        let value = data[el];

        const decimals =
          el.match(/decimals(\d+)/) && +el.match(/decimals(\d+)/)[1];
        const multipliedBy = el.match(/\*(\d+)/) && +el.match(/\*(\d+)/)[1];

        if (el.includes("[") && value.includes(",")) {
          value = value.split(",");
        } else if (decimals) {
          value = utils.parseUnits(value, +decimals);
        } else if (multipliedBy) {
          value = value * multipliedBy;
        }
        return {
          order: +el.split("index")[1],
          value,
        };
      })
      .sort((a, b) => a.order - b.order)
      .map((el) => el.value);

  const handleSubmit = async () => {
    const params = parseParams(formData);
    const method = methodSignature.split("(")[0];
    console.log(params, method);
    const { data } = await contract.populateTransaction[method](...params);

    const tx = await submitTransaction(
      contracts.getContractByName(multisig),
      contract.address,
      0,
      data,
    );
    return await tx.wait();
  };

  const renderInput = (param, index) => {
    const name = `${param.type}${param.isArray ? "[]" : ""};index${index}`;

    if (
      param.type === "" ||
      param.type.includes(":kos") ||
      param.type.includes("=")
    ) {
      return null;
    } else if (param.isArray) {
      return (
        <div key={index}>
          <label>{`Parameter ${index + 1} (${param.type})`}</label>
          <Input
            type="text"
            name={name}
            value={formData[name]}
            setValue={handleChange}
            placeholder={`Enter ${param.type} values separated by commas`}
            returnEvent
          />
        </div>
      );
    } else if (param.type === "bool") {
      return (
        <div key={index}>
          <label>{`Parameter ${index + 1} (${param.type})`}</label>
          <UiCheckbox
            handleChange={() => handleBool(name)}
            checked={formData[name]}
            name={name}
          />
        </div>
      );
    } else {
      const paramName = param.type.includes(":") && param.type.split(":")[1];
      let placeholder = `Enter ${param.type} value`;

      if (param.type.includes("address")) {
        placeholder = ethers.constants.AddressZero;
      } else if (param.type.includes("%")) {
        placeholder = "0%";
      } else if (param.type.includes("uint")) {
        placeholder = "0";
      }

      return (
        <div key={index}>
          <label>{paramName || `Parameter ${index + 1} (${param.type})`}</label>
          <Input
            type={param.type.includes("uint") ? "number" : "text"}
            name={name}
            value={formData[name]}
            setValue={handleChange}
            placeholder={placeholder}
            returnEvent
          />
        </div>
      );
    }
  };

  return (
    <Form handler={handleSubmit} key={methodSignature} contract={contract}>
      <h3>{`Method: ${methodName}`}</h3>
      {params.map((param, index, type) => renderInput(param, index))}
    </Form>
  );
};

const ContractMethodsForm = ({
  contract,
  methods,
  children,
  label = "",
  multisig = MainContractNames.Ecosystem_BondMarketplaceMultisig,
}) => {
  return (
    <div className="page">
      <div className="finance-transfer-heading">
        <h3>{label}</h3>
        <span className="finance-transfer-heading__address">
          {contract.address}
        </span>
      </div>
      {children}
      {methods.map((el) => (
        <MethodParamsForm
          key={contract.address + el}
          methodSignature={el}
          contract={contract}
          multisig={multisig}
        />
      ))}
    </div>
  );
};

export default ContractMethodsForm;
