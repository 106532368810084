import { useState, useEffect } from 'react';
import { BigNumber, utils } from 'ethers';
import Modal from '../../../UI/Modal';
import { InputAddress } from '../../../UI/Inputs';
import { InputAmount } from '../../../UI/Inputs';
import Button from '../../../UI/Button';

import useClaimFeesHandler from '../../../../lib/hooks/use-claim-fees-handler';
import { displayAmount } from '../../../../utils';

const ModalInColumn = ({ row }) => {
  const balanceFrom = BigNumber.from(row.balance);
  const formatAmount = +utils.formatUnits(balanceFrom, row.decimals);

  const {
    isLoading,
    amount,
    addressTo,
    setAmount,
    setAddressTo,
    handleOpen,
    handleSubmit,
    isModalOpen,
    handleClose,
    isDisabled,
  } = useClaimFeesHandler(row);

  useEffect(() => {
    if (!isModalOpen) {
      setAddressTo('');
      setAmount(displayAmount(formatAmount, 6));
    }
  }, [isModalOpen, formatAmount, setAddressTo, setAmount]);

  return (
    <>
      <Button
        disabled={balanceFrom.isZero()}
        onClick={!balanceFrom.isZero() ? handleOpen : undefined}
      >
        Withdraw
      </Button>
      {isModalOpen && (
        <Modal handleClose={handleClose}>
          <div className="form-box">
            <InputAddress
              title={'Address to'}
              value={addressTo}
              setValue={setAddressTo}
            />
            <InputAmount
              title={'Amount'}
              value={amount}
              setValue={setAmount}
              inputProps={{ placeholder: amount }}
            />
            <Button
              onClick={!isDisabled() ? handleSubmit : undefined}
              disabled={isDisabled()}
              isLoading={isLoading}
              withArrow
            >
              Submit
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalInColumn;
