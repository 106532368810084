import { submitAstraMultisigTx } from "../../../../utils/submitAstraMultisigTx";
import { constants, ethers, utils } from "ethers";
import erc20abi from "../../../../utils/abi/erc20token";
import { Form } from "../../../UI/Form";
import { Input } from "../../../UI/Inputs";
import { useEffect, useMemo, useState } from "react";
import useContracts from "../../../../contexts/ContractsContext/useContracts";
import Treshold from "./Treshold";
import UiSelect from "../../../UI/UiSelect";
import { eclipsePairs, eclipseTokens } from "../../../../utils/constants";
import { ContractNames } from "@airdao/airdao-node-contracts";

const PairManager = ({ contract }) => {
  const { provider, contracts, chainId } = useContracts();
  const [pair, setPair] = useState("");
  const [createPoolData, setCreatePoolData] = useState({
    token: "",
    amount: "",
  });
  const [fundTreasuryData, setFundTreasuryData] = useState({
    amount: "",
  });
  const [withdrawTreasuryData, setWithdrawTreasury] = useState({
    amount: "",
    to: "",
  });
  const [closePoolData, setClosePoolData] = useState("");
  const [treasuryBalance, setTreasuryBalance] = useState(0);
  const [pairDeployed, setPairDeployed] = useState(null);
  const [balances, setBalances] = useState([]);

  useEffect(() => {
    getBalances().then(setBalances);
  }, []);

  const getBalances = async () => {
    return await Promise.all(
      eclipsePairs[chainId].map(async (el) => {
        const treasuryAddress = await contract.treasury(el.value);
        const treasury = new ethers.Contract(
          treasuryAddress,
          [
            "function treasuryBalance() public view override returns (uint256)",
            "function totalLiquidity() public view returns (uint256)",
          ],
          provider,
        );
        const balance = await treasury.treasuryBalance();
        const liquidity = await treasury.totalLiquidity();

        return {
          balance: +utils.formatEther(balance),
          liquidity: +utils.formatEther(liquidity),
          name: el.label,
        };
      }),
    );
  };

  useEffect(() => {
    (async () => {
      if (utils.isAddress(pair)) {
        let treasuryAddress = "";
        try {
          treasuryAddress = await contract.treasury(pair);
        } catch (e) {
          console.log(e);
        }
        if (!!treasuryAddress) {
          setPairDeployed(1);
        } else {
          setPairDeployed(0);
          return;
        }
        const treasuryContract = new ethers.Contract(
          treasuryAddress,
          ["function treasuryBalance() view returns (uint256)"],
          provider,
        );
        const balance = await treasuryContract.treasuryBalance();
        setTreasuryBalance(+utils.formatEther(balance));
      } else {
        setPairDeployed(null);
        setTreasuryBalance(0);
      }
    })();
  }, [pair]);

  const multisig = contracts.getContractByName(
    ContractNames.Ecosystem_AstradexMultisig,
  );

  const handleCreatePoolData = (event) => {
    const { name, value } = event.target;
    setCreatePoolData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleFundTreasuryData = (event) => {
    const { name, value } = event.target;
    setFundTreasuryData((state) => ({
      ...state,
      [name]: value,
    }));
  };
  const handleWithdrawTreasuryData = (event) => {
    const { name, value } = event.target;
    setWithdrawTreasury((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const approveAllowance = async (tokenContract, amountBn) => {
    const tx = await tokenContract.transfer(multisig.address, amountBn);
    await tx.wait();

    const { data } = await tokenContract.populateTransaction.approve(
      contract.current.address,
      amountBn,
    );

    const tx2 = await submitAstraMultisigTx(
      contracts,
      tokenContract.address,
      data,
    );
    return await tx2.wait();
  };

  const isEnoughAllowance = async (tokenContract, amountBn) => {
    const allowance = await tokenContract.allowance(
      multisig.address,
      contract.current.address,
    );
    return amountBn.lte(allowance);
  };

  const submitCreatePool = async () => {
    const { token, amount } = createPoolData;
    const amountBn = utils.parseEther(amount);

    if (token !== constants.AddressZero) {
      const tokenContract = new ethers.Contract(
        token,
        erc20abi,
        provider.getSigner(),
      );

      if (!(await isEnoughAllowance(tokenContract, amountBn))) {
        return await approveAllowance(tokenContract, amountBn);
      }
    }

    const { data } = await contract.current.populateTransaction.createPool(
      pair,
      token,
      amountBn,
    );

    const tx = await submitAstraMultisigTx(
      contracts,
      contract.current.address,
      data,
      token === constants.AddressZero ? amountBn : 0,
    );
    return await tx.wait();
  };

  const submitFundTreasury = async () => {
    const { amount } = fundTreasuryData;
    const amountBn = utils.parseEther(amount);

    const treasuryAddress = contract.treasury(pair);
    const treasuryContract = new ethers.Contract(
      treasuryAddress,
      ["function token() view returns (address)"],
      provider.getSigner(),
    );
    const tokenAddress = await treasuryContract.token();

    if (tokenAddress !== "0x2Cf845b49e1c4E5D657fbBF36E97B7B5B7B7b74b") {
      const tokenContract = new ethers.Contract(
        tokenAddress,
        erc20abi,
        provider.getSigner(),
      );

      if (!(await isEnoughAllowance(tokenContract, amountBn))) {
        return await approveAllowance(tokenContract, amountBn);
      }
    }
    const { data } = await contract.populateTransaction.fundTreasury(
      pair,
      amountBn,
    );

    const tx = await submitAstraMultisigTx(
      contracts,
      contract.address,
      data,
      tokenAddress === "0x2Cf845b49e1c4E5D657fbBF36E97B7B5B7B7b74b"
        ? amountBn
        : 0,
    );
    return await tx.wait();
  };

  const submitTx = async (params, method) => {
    const { data } = await contract.populateTransaction[method](...params);

    const tx = await submitAstraMultisigTx(contracts, contract.address, data);
    return await tx.wait();
  };

  const submitWithdrawTreasury = async () => {
    return submitTx(
      [
        pair,
        utils.parseUnits(withdrawTreasuryData.amount, 18),
        withdrawTreasuryData.to,
      ],
      "withdrawTreasury",
    );
  };
  const submitClosePool = async () => {
    return submitTx([pair, closePoolData], "closePool");
  };
  const submitPausePool = async () => {
    return submitTx([pair], "pausePool");
  };
  const submitUnpausePool = async () => {
    return submitTx([pair], "unpausePool");
  };

  const selectedPairToken = useMemo(() => {
    if (!pair) return "";
    return eclipsePairs[chainId]
      .find((el) => el.value === pair)
      .label.split("/")[0];
  }, [pair]);

  return (
    <div className="page">
      <div>
        <div className="eclipse-list">
          <span>Name</span>
          <span>Total liquidity</span>
          <span>Balance</span>
        </div>
        {balances.map((el) => (
          <div className="eclipse-list">
            <span>{el.name}</span>
            <span>{el.liquidity.toLocaleString()}</span>
            <span>{el.balance.toLocaleString()}</span>
          </div>
        ))}
      </div>
      {!!treasuryBalance && (
        <p>
          Treasury balance:{" "}
          <b>
            {treasuryBalance.toLocaleString()} {selectedPairToken}
          </b>
        </p>
      )}
      <UiSelect
        options={eclipsePairs[chainId]}
        selectedValue={pair}
        onChange={setPair}
      />
      {pairDeployed === 0 && (
        <Form handler={submitCreatePool} contract={contract}>
          <h3>Method: createPool</h3>
          <UiSelect
            options={eclipseTokens}
            selectedValue={createPoolData.token}
            onChange={(value) =>
              handleCreatePoolData({ target: { name: "token", value } })
            }
          />
          <Input
            type="number"
            value={createPoolData.amount}
            setValue={handleCreatePoolData}
            title="Amount"
            name="amount"
            placeholder="0"
            returnEvent
          />
        </Form>
      )}
      {pairDeployed === 1 && (
        <>
          <Form handler={submitFundTreasury} contract={contract}>
            <h3>Method: fundTreasury</h3>
            <Input
              type="number"
              value={fundTreasuryData.amount}
              setValue={handleFundTreasuryData}
              title="Amount"
              name="amount"
              placeholder="0"
              returnEvent
            />
          </Form>
          <Form handler={submitWithdrawTreasury} contract={contract}>
            <h3>Method: withdrawTreasury</h3>
            <Input
              value={withdrawTreasuryData.amount}
              setValue={handleWithdrawTreasuryData}
              title="Amount"
              name="amount"
              placeholder={ethers.constants.AddressZero}
              returnEvent
            />
            <Input
              value={withdrawTreasuryData.to}
              setValue={handleWithdrawTreasuryData}
              title="Address to"
              name="to"
              placeholder={ethers.constants.AddressZero}
              returnEvent
            />
          </Form>
          <Form handler={submitClosePool} contract={contract}>
            <h3>Method: closePool</h3>
            <Input
              value={closePoolData}
              setValue={setClosePoolData}
              title="Address to"
              placeholder={ethers.constants.AddressZero}
            />
          </Form>
          <Form handler={submitPausePool} contract={contract}>
            <h3>Method: pausePool</h3>
          </Form>
          <Form handler={submitUnpausePool} contract={contract}>
            <h3>Method: unpausePool</h3>
          </Form>
          <Treshold contract={contract} pair={pair} />
        </>
      )}
    </div>
  );
};

export default PairManager;
