import getContractInfo from "./getContractInfo";

export default function parseChangeSignersMaster(inputs, contracts) {
	if (!inputs.length) return {};

	const changesByUser = {};

	for (let value of inputs[0].value) {
		const groupAddress = value[0];
		const groupInfo = getContractInfo(contracts, groupAddress);
		const groupName = groupInfo.type + (groupInfo.group ? `: ${groupInfo.group}` : '');

		const usersToRemove = value[1];

		usersToRemove.forEach((user) => {
			if (!changesByUser[user]) changesByUser[user] = {};
			if (!changesByUser[user].removeFrom) changesByUser[user].removeFrom = [];
			changesByUser[user].removeFrom.push(groupName);
		});

		const usersToAdd = value[2];
		const isAddedUserInitiator = value[3];

		usersToAdd.forEach((user, i) => {
			if (!changesByUser[user]) changesByUser[user] = {};
			if (!changesByUser[user].addTo) changesByUser[user].addTo = [];
			changesByUser[user].addTo.push({ group: groupName, isInitiator: isAddedUserInitiator[i] });
		});
	}

	return { changes: createChangesString(changesByUser)};
}

const createChangesString = (changesByUser) => {
	let resString = '';
	Object.keys(changesByUser).forEach((user) => {
		resString += `${user}:\n`;

		const { addTo, removeFrom } = changesByUser[user];
		if (addTo) {
			addTo.forEach((group) => {
				resString += `Add to "${group.group}" wallet as ${group.isInitiator ? 'initiator' : 'member'}\n`;
			});
		}

		if (removeFrom) {
			removeFrom.forEach((group) => {
				resString += `Remove from "${group}" wallet\n`;
			});
		}

	});

	return resString;
}
