import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { ContractNames } from "@airdao/airdao-node-contracts";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import { hbrTokenMethods } from "../../../../utils/constants";
import {useEffect, useState} from "react";
import {Input} from "../../../UI/Inputs";
import {ethers, utils} from "ethers";
import {Form} from "../../../UI/Form";
import {submitTransaction} from "../../../../utils/submitTransaction";
import {ContractNames as MultisigContractNames} from "@airdao/airdao-node-contracts/dist/src/contracts/names";

const HarborToken = () => {
  const { contracts } = useContracts();

  const contract = contracts.getContractByName(
    ContractNames.Ecosystem_HBRToken,
  );

  const multisig = contracts.getContractByName(
    MultisigContractNames.Ecosystem_LimitedTokenPoolsManagerMultisig,
  );
  const [burnData, setBurnData] = useState({
    address: '',
    amount: ''
  });
  const [balance, setBalance] = useState('');

  useEffect(() => {
    if (burnData.address) {
      contract.balanceOf(burnData.address)
        .then((res) => setBalance(utils.formatEther(res)));
    }
  }, [burnData]);

  const handleBurnData = (event) => {
    const {value, name} = event.target;
    setBurnData((state) => ({...state, [name]: value}));
  };

  const submitBurn = async () => {
    const { data } =
      await contract.populateTransaction.burn(burnData.address, burnData.amount);

    const tx = await submitTransaction(multisig, contract.address, 0, data);
    return await tx.wait();
  };

  return (
    <ContractMethodsForm
      contract={contract}
      multisig={ContractNames.Ecosystem_LimitedTokenPoolsManagerMultisig}
      methods={hbrTokenMethods}
      label="HBR Token"
    >
      <Form contract={contract} handler={submitBurn}>
        <h3>method: burn</h3>
        {burnData.address && (
          <p>
            Balance: <b>{balance} HBR</b>
          </p>
        )}
        <Input
          value={burnData.address}
          setValue={handleBurnData}
          title="Address"
          name="address"
          placeholder={ethers.constants.AddressZero}
          returnEvent
        />
        <Input
          value={burnData.amount}
          setValue={handleBurnData}
          title="Amount"
          name="amount"
          placeholder="0"
          returnEvent
        />
      </Form>
    </ContractMethodsForm>
  );
};

export default HarborToken;
