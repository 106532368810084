import ContractMethodsForm from "../../../../UI/ContractMethodsForm";
import useContracts from "../../../../../contexts/ContractsContext/useContracts";
import { useEffect, useState } from "react";
import { ContractNames } from "@airdao/airdao-node-contracts";
import {
  explorerApi,
  liquidStakingStorageRewardMethods,
} from "../../../../../utils/constants";
import axios from "axios";
import { Form } from "../../../../UI/Form";
import { Input } from "../../../../UI/Inputs";
import { ethers, utils } from "ethers";
import { submitLiquidStakingMultisigTx } from "../../../../../utils/submitLiquidStakingMultisigTx";

const StorageRewards = ({ contract, label, multisigName }) => {
  const { contracts, chainId } = useContracts();

  const [explorerData, setExplorerData] = useState(null);
  const [withdrawBondForm, setWithdrawBondForm] = useState({
    address: "",
    amount: "",
  });

  const rewardsBankContract = contracts.getContractByName(contract);

  useEffect(() => {
    const { address } = contracts.getContractByName(contract);
    axios.get(`${explorerApi[chainId]}addresses/${address}/all`).then((res) => {
      if (res.data) {
        setExplorerData(res.data);
      }
    });
  }, []);

  const handleBondWithdrawForm = (event) => {
    const { name, value } = event.target;
    setWithdrawBondForm((state) => ({ ...state, [name]: value }));
  };

  const handleBondWithdraw = async () => {
    const { address } = explorerData.tokens.find((el) => el.symbol === "BOND");

    const { data } =
      await rewardsBankContract.populateTransaction.withdrawErc20(
        address,
        withdrawBondForm.address,
        utils.parseEther(withdrawBondForm.amount),
      );

    const tx = await submitLiquidStakingMultisigTx(
      contracts,
      rewardsBankContract.address,
      data,
    );
    return await tx.wait();
  };

  return (
    <>
      <ContractMethodsForm
        contract={rewardsBankContract}
        label={label}
        methods={liquidStakingStorageRewardMethods}
        multisig={multisigName}
      >
        <div>
          <p>
            AMB balance:{" "}
            <b>
              {explorerData && explorerData?.account?.balance?.ether.toFixed(2)}
            </b>
          </p>
          {contract === ContractNames.Ecosystem_LiquidPoolRewardsBank && (
            <p>
              BOND balance:{" "}
              <b>
                {explorerData && explorerData.tokens.length
                  ? explorerData.tokens
                      .find((el) => el.symbol === "BOND")
                      ?.balance?.ether.toFixed(2)
                  : 0}
              </b>
            </p>
          )}
        </div>
        {contract === ContractNames.Ecosystem_LiquidPoolRewardsBank && (
          <Form handler={handleBondWithdraw} contract={rewardsBankContract}>
            <h3>Withdraw BOND</h3>
            <Input
              value={withdrawBondForm.address}
              setValue={handleBondWithdrawForm}
              name="address"
              title="Address"
              placeholder={ethers.constants.AddressZero}
              returnEvent
            />
            <Input
              type="number"
              value={withdrawBondForm.amount}
              setValue={handleBondWithdrawForm}
              name="amount"
              title="Amount"
              placeholder="0"
              returnEvent
            />
          </Form>
        )}
      </ContractMethodsForm>
    </>
  );
};

export default StorageRewards;
