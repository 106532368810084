import useContracts from "../../../../../contexts/ContractsContext/useContracts";
import { ContractNames } from "@airdao/airdao-node-contracts";
import ContractMethodsForm from "../../../../UI/ContractMethodsForm";
import {
  liquidStakingTreasuryFeeMethods,
  liquidStakingTreasuryMethods,
} from "../../../../../utils/constants";
import { useEffect, useState } from "react";
import { utils } from "ethers";
import { submitTransaction } from "../../../../../utils/submitTransaction";

const Treasury = () => {
  const { contracts, provider } = useContracts();

  const treasuryFeesContract = contracts.getContractByName(
    ContractNames.Ecosystem_LiquidNodesManagerTreasuryFees,
  );
  const treasuryContract = contracts.getContractByName(
    ContractNames.Ecosystem_LiquidNodesManagerTreasury,
  );

  const [treasuryFeeData, setTreasuryFeeData] = useState({
    fee: "",
    balance: "",
  });
  const [treasuryData, setTreasuryData] = useState({
    fee: "",
    balance: "",
  });

  useEffect(() => {
    treasuryFeesContract.fee().then((res) =>
      setTreasuryFeeData((state) => ({
        ...state,
        fee: utils.formatUnits(res, 0),
      })),
    );

    provider.getBalance(treasuryFeesContract.address).then((res) =>
      setTreasuryFeeData((state) => ({
        ...state,
        balance: utils.formatEther(res),
      })),
    );

    treasuryContract.fee().then((res) =>
      setTreasuryData((state) => ({
        ...state,
        fee: utils.formatUnits(res, 0),
      })),
    );

    provider.getBalance(treasuryContract.address).then((res) =>
      setTreasuryData((state) => ({
        ...state,
        balance: utils.formatEther(res),
      })),
    );
  }, []);

  return (
    <>
      <ContractMethodsForm
        methods={liquidStakingTreasuryFeeMethods}
        contract={treasuryFeesContract}
        multisig={ContractNames.Ecosystem_LiquidPoolMultisig}
        label="Treasury fees"
      >
        <div>
          <p>
            Current fee: <b>{+treasuryFeeData.fee / 100}%</b>
          </p>
          <p>
            Balance: <b>{treasuryFeeData.balance} AMB</b>
          </p>
        </div>
      </ContractMethodsForm>
      <ContractMethodsForm
        methods={liquidStakingTreasuryMethods}
        contract={treasuryContract}
        multisig={ContractNames.Ecosystem_LiquidPoolMultisig}
        label="Treasury"
      >
        <div>
          <p>
            Balance: <b>{treasuryData.balance} AMB</b>
          </p>
        </div>
      </ContractMethodsForm>
    </>
  );
};

export default Treasury;
