import { useEffect, useRef, useState } from "react";
import { ethers, utils } from "ethers";
import useContracts from "../../../../contexts/ContractsContext/useContracts";
import abi from "../../../../utils/abi/borrowing";
import borrowing from "../../../../utils/abi/borrowing";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import {
  orbitLendMethods,
  orbitOracleMethods,
} from "../../../../utils/constants";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { Form } from "../../../UI/Form";
import { Input } from "../../../UI/Inputs";
import erc20abi from "../../../../utils/abi/erc20token";
import approveFund from "../../../../utils/approveFund";
import { submitLiquidStakingMultisigTx } from "../../../../utils/submitLiquidStakingMultisigTx";
const Borrowing = () => {
  const { contracts, chainId, provider } = useContracts();

  const orbitLendAbi = borrowing[chainId].OrbitLend;
  const orbitOracleAbi = borrowing[chainId].OrbitOracle;

  const orbitLend = useRef(
    new ethers.Contract(
      orbitLendAbi.address,
      orbitLendAbi.abi,
      provider.getSigner(),
    ),
  );
  const orbitOracle = useRef(
    new ethers.Contract(
      orbitOracleAbi.address,
      orbitOracleAbi.abi,
      provider.getSigner(),
    ),
  );

  const multisig = contracts.getContractByName(
    ContractNames.Ecosystem_LiquidPoolMultisig,
  );

  const [fundData, setFundData] = useState({ token: "", amount: "" });
  const [withdrawData, setWithdrawData] = useState({
    token: '',
    amount: '',
    to: '',
  });
  const [fundContractData, setFundContractData] = useState({});
  const [withdrawContractData, setWithdrawContractData] = useState({});

  useEffect(() => {
    getTokenDataBalance(fundData.token)
      .then(setFundContractData);
  }, [fundData]);

  useEffect(() => {
    getTokenDataBalance(withdrawData.token)
      .then(setWithdrawContractData);
  }, [withdrawData]);

  const getTokenDataBalance = async (tokenAddress) => {
    if (utils.isAddress(tokenAddress)) {
      const treasuryAddress = await orbitLend.current.tokenTreasuries(tokenAddress);
      const token = new ethers.Contract(tokenAddress, erc20abi, provider);
      const symbol = await token.symbol();
      const balance = await token.balanceOf(treasuryAddress);
      console.log(balance, symbol);
      return { balance: utils.formatEther(balance), symbol };
    } else return {}
  }

  const handleFundData = (event) => {
    const { name, value } = event.target;
    setFundData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleWithdrawData = (event) => {
    const { name, value } = event.target;
    setWithdrawData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const isEnoughAllowance = async (tokenContract, amountBn) => {
    const allowance = await tokenContract.allowance(
      multisig.address,
      orbitLend.current.address,
    );
    return amountBn.lte(allowance);
  };

  const submitFund = async () => {
    const amountBn = utils.parseEther(fundData.amount);

    const tokenContract = new ethers.Contract(
      fundData.token,
      erc20abi,
      provider.getSigner(),
    );
    if (!(await isEnoughAllowance(tokenContract, amountBn))) {
      return approveFund(
        tokenContract,
        amountBn,
        multisig,
        orbitLend.current.address,
      );
    }
    const { data } = await orbitLend.current.populateTransaction.fundTreasury(
      fundData.token,
      amountBn,
    );

    const tx = await submitLiquidStakingMultisigTx(
      contracts,
      orbitLend.current.address,
      data,
    );
    return await tx.wait();
  };

  const submitWithdraw = async () => {
    const { data } = await orbitLend.current.populateTransaction.withdrawTreasury(
      withdrawData.token,
      utils.parseEther(withdrawData.amount),
      withdrawData.to,
    );

    const tx = await submitLiquidStakingMultisigTx(
      contracts,
      orbitLend.current.address,
      data,
    );
    return await tx.wait();
  }

  return (
    <>
      <ContractMethodsForm
        label="Orbit Lend"
        methods={orbitLendMethods}
        contract={orbitLend.current}
        multisig={ContractNames.Ecosystem_LiquidPoolMultisig}
      >
        <Form contract={orbitLend.current} handler={submitFund}>
          <h3>method: fundTreasury</h3>
          {fundContractData.symbol && (
            <p>
              Token balance: <b>{fundContractData.balance} {fundContractData.symbol}</b>
            </p>
          )}
          <Input
            value={fundData.token}
            setValue={handleFundData}
            title="Token"
            name="token"
            placeholder={ethers.constants.AddressZero}
            returnEvent
          />
          <Input
            value={fundData.amount}
            setValue={handleFundData}
            title="Amount"
            name="amount"
            placeholder="0"
            returnEvent
          />
        </Form>
        <Form contract={orbitLend.current} handler={submitWithdraw}>
          <h3>method: withdrawTreasury</h3>
          {withdrawContractData.symbol && (
            <p>
              Token balance: <b>{withdrawContractData.balance} {withdrawContractData.symbol}</b>
            </p>
          )}
          <Input
            value={withdrawData.token}
            setValue={handleWithdrawData}
            title="Token"
            name="token"
            placeholder={ethers.constants.AddressZero}
            returnEvent
          />
          <Input
            value={withdrawData.amount}
            setValue={handleWithdrawData}
            title="Amount"
            name="amount"
            placeholder="0"
            returnEvent
          />
          <Input
            value={withdrawData.to}
            setValue={handleWithdrawData}
            title="To"
            name="to"
            placeholder={ethers.constants.AddressZero}
            returnEvent
          />
        </Form>
      </ContractMethodsForm>
      <ContractMethodsForm
        label="Orbit Oracle"
        methods={orbitOracleMethods}
        contract={orbitOracle.current}
        multisig={ContractNames.Ecosystem_LiquidPoolMultisig}
      />
    </>
  );
};

export default Borrowing;
