import {ethers} from "ethers";

export default function parseWithdraw(inputs) {
	if (!inputs.length) return {};

	const addressTo = inputs[0].value;

	const bnAmount = inputs[1].value;
	const amount = ethers.utils.formatEther(bnAmount) + ' AMB';

	return { destination: addressTo, amount };
}
