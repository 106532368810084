import { ContractNames } from "@airdao/airdao-bond";
import { useEffect, useMemo, useState } from "react";
import {
  auctioneersMethods,
  SDAAuctioneersMethods,
} from "../../../../utils/constants";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { Form } from "../../../UI/Form";
import UiCheckbox from "../../../UI/UiCheckbox";
import { submitBondMarketplaceMultisigTx } from "../../../../utils/submitBondMarketplaceMultisigTx";

const tabs = ["term", "expiry"];

const Item = ({ name, methods }) => {
  const { bondContracts, contracts } = useContracts();

  const [allowNewMarkets, setAllowNewMarkets] = useState(false);
  const contract = bondContracts.getContractByName(name);

  useEffect(() => {
    contract.allowNewMarkets().then(setAllowNewMarkets);
  }, []);

  const submitAllow = async () => {
    const { data } =
      await contract.populateTransaction.setAllowNewMarkets(allowNewMarkets);

    const tx = await submitBondMarketplaceMultisigTx(
      contracts,
      contract.address,
      data,
    );
    return await tx.wait();
  };

  return (
    <ContractMethodsForm
      label={name}
      contract={bondContracts.getContractByName(name)}
      methods={methods}
    >
      <Form handler={submitAllow} contract={contract}>
        <p>Method: setAllowNewMarkets</p>
        <label>(bool)</label>
        <UiCheckbox
          handleChange={() => setAllowNewMarkets((state) => !state)}
          checked={allowNewMarkets}
          name={name}
        />
      </Form>
    </ContractMethodsForm>
  );
};

const Auctioneers = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const auctioneersList = useMemo(
    () => ({
      term: [
        { name: ContractNames.FixedTermFPA, methods: auctioneersMethods },
        { name: ContractNames.FixedTermSDA, methods: SDAAuctioneersMethods },
        { name: ContractNames.FixedTermFPDA, methods: auctioneersMethods },
      ],
      expiry: [
        { name: ContractNames.FixedExpiryFPA, methods: auctioneersMethods },
        { name: ContractNames.FixedExpirySDA, methods: SDAAuctioneersMethods },
        { name: ContractNames.FixedExpiryFPDA, methods: auctioneersMethods },
      ],
    }),
    [],
  );

  return (
    <>
      <div className="page page_wide tabs-heading">
        {tabs.map((el) => (
          <button
            key={el}
            type="button"
            onClick={() => setSelectedTab(el)}
            className={`tabs-btn capitalized ${selectedTab === el ? "tabs-btn_selected" : ""}`}
          >
            {el}
          </button>
        ))}
      </div>
      {auctioneersList[selectedTab].map(({ name, methods }) => (
        <Item name={name} methods={methods} key={name} />
      ))}
    </>
  );
};

export default Auctioneers;
