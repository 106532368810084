import React from "react";

const UiCheckbox = ({ checked = false, handleChange, label, name }) => (
  <label className={`ui-checkbox ${checked ? "ui-checkbox_checked" : ""}`}>
    <span className="ui-checkbox__label">{label}</span>
    <input
      name={name}
      type="checkbox"
      className="ui-checkbox__input"
      onChange={handleChange}
      checked={checked ? "checked" : ""}
    />
    <span className="ui-checkbox__checkmark" />
  </label>
);

export default UiCheckbox;
