import { useMemo, useState } from "react";
import { ContractNames } from "@airdao/airdao-bond";
import { tellerMethods } from "../../../../utils/constants";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { submitBondMarketplaceMultisigTx } from "../../../../utils/submitBondMarketplaceMultisigTx";
import { Input } from "../../../UI/Inputs";
import { Form } from "../../../UI/Form";

const tabs = ["term", "expiry"];

const Teller = () => {
  const { bondContracts } = useContracts();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const tellersList = useMemo(
    () => ({
      term: [{ name: ContractNames.FixedTermTeller, methods: tellerMethods }],
      expiry: [
        { name: ContractNames.FixedExpiryTeller, methods: tellerMethods },
      ],
    }),
    [],
  );

  return (
    <>
      <div className="page page_wide tabs-heading">
        {tabs.map((el) => (
          <button
            key={el}
            type="button"
            onClick={() => setSelectedTab(el)}
            className={`tabs-btn capitalized ${selectedTab === el ? "tabs-btn_selected" : ""}`}
          >
            {el}
          </button>
        ))}
      </div>
      {tellersList[selectedTab].map(({ name, methods }) => (
        <ContractMethodsForm
          label={name}
          methods={methods}
          contract={bondContracts.getContractByName(name)}
        />
      ))}
    </>
  );
};

export default Teller;
