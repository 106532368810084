import React, { useEffect, useMemo, useState } from "react";
import UiCheckbox from "./UI/UiCheckbox";
import InitCheckbox from "./UI/InitCheckbox";
import arrow from "../assets/svg/arrow.svg";
import ApprovedMark from "./UI/ApprovedMark";
import Modal from "./UI/Modal";
import { ContractNames, Multisig } from "@airdao/airdao-node-contracts";
import InlineLoader from "./UI/InlineLoader";
import { utils } from "ethers";
import { getContractLabel } from "../utils/contractLabels";

const HandleUserModal = ({ handleOpen, userData = {}, contracts }) => {
  const [address, setAddress] = useState(userData.address || "");
  const [selectedGroups, setSelectedGroups] = useState({});
  const [initSelected, setInitSelected] = useState({});
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [requiredError, setRequiredError] = useState(false);
  const [isAddressInvalid, setIsAddressInvalid] = useState(false);

  const isAddUserModal = useMemo(
    () => !Object.keys(userData).length,
    [userData],
  );

  useEffect(() => {
    if (!isAddUserModal) {
      userData.groups.forEach((el) => {
        setInitSelected((state) => ({
          ...state,
          [el.address]: el.isInitiator,
        }));
        setSelectedGroups((state) => ({ ...state, [el.address]: true }));
      });
    }
  }, []);

  const availablePerms = contracts
    .getAllMultisigNames()
    .map((name) => ({
      name,
      address: contracts.getContractByNameSafe(name)?.address,
    }))
    .filter((el) => el.address);

  const handleAddress = ({ target }) => {
    setIsAddressInvalid(false);
    setAddress(target.value);
  };

  const handleInvalid = ({ target }) => {
    if (target.value) {
      setIsAddressInvalid(!utils.isAddress(target.value));
    }
  };

  const handleGroups = (value) => {
    const clone = { ...selectedGroups };

    clone[value] = !selectedGroups[value];
    setSelectedGroups((state) => ({ ...state, ...clone }));

    setRequiredError(false);
    setError("");
  };

  const handleInit = (value) => {
    const clone = { ...initSelected };

    clone[value] = !initSelected[value];
    setInitSelected((state) => ({ ...state, ...clone }));

    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isAddUserModal && !Object.values(selectedGroups).some((el) => el)) {
      setRequiredError(true);
      return;
    }

    if (isAddressInvalid) {
      return;
    }

    const newUserGroups = Object.keys(selectedGroups)
      .filter((address) => selectedGroups[address])
      .map((address) => ({ address, isInitiator: !!initSelected[address] }));

    Multisig.setUserGroups(
      contracts,
      address,
      newUserGroups,
      userData.groups || [],
    )
      .then((res) => {
        setIsLoading(true);
        res
          .wait()
          .then((res) => setResult(res))
          .finally(() => setIsLoading(false));
      })
      .catch((e) => {
        if (e.message && e.message.length < 100) {
          setError(e.message);
        } else {
          console.log(e.message);
        }
      });
  };

  return (
    <Modal
      title={`${!isAddUserModal ? "Edit" : "Add"} user`}
      handleClose={handleOpen}
    >
      <form onSubmit={handleSubmit}>
        <span className="modal-label">Address</span>
        <input
          type="text"
          className="ui-input"
          value={address}
          placeholder="0x0000000000000000000000000000000000000000"
          onChange={handleAddress}
          onBlur={handleInvalid}
          disabled={!!userData.address}
          required
        />
        {isAddressInvalid && (
          <span className="error-message">Incorrect address</span>
        )}
        <span className="modal-label">Groups</span>
        <ul className="manage-groups-list">
          {availablePerms.map(({ name, address }) => (
            <li className="manage-groups-item" key={name}>
              <UiCheckbox
                handleChange={() => handleGroups(address)}
                checked={selectedGroups[address]}
                label={getContractLabel(name)}
              />
              {selectedGroups[address] && (
                <InitCheckbox
                  handleChange={() => handleInit(address)}
                  checked={initSelected[address] ? "checked" : ""}
                />
              )}
            </li>
          ))}
        </ul>
        {requiredError && (
          <span
            className="error-message"
            style={{ margin: "-20px 0 20px", display: "block" }}
          >
            Field is required
          </span>
        )}
        <div className="modal__actions-bottom">
          <button className="ui-button">
            Submit
            <img src={arrow} alt="arrow" />
          </button>
          {isLoading && <InlineLoader />}
          {(!!result || error) &&
            (error ? (
              <span className="error-message">{error}</span>
            ) : (
              <ApprovedMark />
            ))}
        </div>
      </form>
    </Modal>
  );
};

export default HandleUserModal;
