import React from 'react';

const InitCheckbox = ({ handleChange, checked }) => {
  return (
    <label className="control control-checkbox">
      <input
        type="checkbox"
        onChange={handleChange}
        checked={checked}
      />
      <span>Initiator</span>
      <div className="control_indicator"></div>
    </label>
  );
};

export default InitCheckbox;