import { Input } from "../../../UI/Inputs";
import { Form } from "../../../UI/Form";
import { useEffect, useState } from "react";
import { ethers, utils } from "ethers";
import UiSelect from "../../../UI/UiSelect";
import useContracts from "../../../../contexts/ContractsContext/useContracts";
import axios from "axios";
import { explorerApi } from "../../../../utils/constants";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { submitAstraMultisigTx } from "../../../../utils/submitAstraMultisigTx";

const Withdraw = () => {
  const { chainId, contracts } = useContracts();

  const [token, setToken] = useState("");
  const [formData, setFormData] = useState({
    amount: "",
    to: "",
  });
  const [explorerData, setExplorerData] = useState(null);
  const [tokens, setTokens] = useState([]);

  const contract = contracts.getContractByName(
    ContractNames.Ecosystem_Astradex_ChronosFeeCollector,
  );

  useEffect(() => {
    axios
      .get(`${explorerApi[chainId]}addresses/${contract.address}/all?limit=1`)
      .then((res) => setExplorerData(res.data));
  }, [chainId]);

  useEffect(() => {
    if (explorerData) {
      const arr = explorerData.tokens.map((el) => ({
        value: el.address,
        label: el.symbol,
      }));
      setTokens([
        ...arr,
        { value: ethers.constants.AddressZero, label: "AMB" },
      ]);
    }
  }, [explorerData]);

  const handleForm = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({ ...state, [name]: value }));
  };
  const handleSubmit = async () => {
    let data;
    if (token.symbol === "AMB") {
      data = (
        await contract.populateTransaction.withdrawAmb(
          formData.to,
          utils.parseEther(formData.amount),
        )
      ).data;
    } else {
      data = (
        await contract.populateTransaction.withdrawErc20(
          token,
          formData.to,
          utils.parseEther(formData.amount),
        )
      ).data;
    }

    const tx = await submitAstraMultisigTx(contracts, contract.address, data);
    return await tx.wait();
  };

  return (
    <Form contract={contract} handler={handleSubmit}>
      <p>Method: withdraw</p>
      {explorerData && (
        <div>
          <p>Balance:</p>
          <p>AMB - {explorerData.account.balance.ether}</p>
          {explorerData.tokens.map((el) => (
            <p>
              {el.symbol} - {el.balance.ether}
            </p>
          ))}
        </div>
      )}
      <p>Token</p>
      <UiSelect selectedValue={token} onChange={setToken} options={tokens} />
      <Input
        type="number"
        value={formData.amount}
        setValue={handleForm}
        title="Amount"
        name="amount"
        placeholder="0"
        returnEvent
      />
      <Input
        type="text"
        value={formData.to}
        setValue={handleForm}
        title="To"
        name="to"
        placeholder={ethers.constants.AddressZero}
        returnEvent
      />
    </Form>
  );
};

export default Withdraw;
