import React, { useEffect, useMemo, useRef, useState } from "react";
import chevron from "../../assets/svg/chevron.svg";
import { utils } from "ethers";
import { useDetectClickOutside } from "react-detect-click-outside";

const UiSelect = ({ options, selectedValue, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });

  const handleOpen = () => {
    setIsOpen((state) => !state);
  };

  const handleSelect = (value) => {
    onChange(value);
    setIsOpen(false);
  };

  const handleChange = (e) => {
    setIsOpen(false);
    onChange(e.target.value);
  };

  const selected = useMemo(() => {
    if (!selectedValue) return null;
    return options.find((el) => el.value === selectedValue);
  }, [selectedValue]);

  return (
    <div className="dropdown" ref={ref}>
      <input
        required
        value={selectedValue}
        onChange={handleChange}
        type="text"
        placeholder="Past address or choose from list"
        className="ui-input"
        onClick={handleOpen}
      />
      <img
        className={`dropdown__arrow ${isOpen ? "dropdown__arrow_active" : ""}`}
        src={chevron}
        alt="arrow"
      />
      {isOpen && (
        <ul className="dropdown__list">
          {options.map((el) => (
            <li
              key={el.value}
              className="dropdown__item"
              onClick={() => handleSelect(el.value)}
            >
              <span className="dropdown__item-second">
                {utils.isAddress(el.value)
                  ? `${el.value.substring(0, 5)}...${el.value.substring(el.value.length - 5, el.value.length)}`
                  : el.value}
              </span>
              <span className="dropdown__item-first">{el.label}</span>
            </li>
          ))}
        </ul>
      )}
      {selectedValue && selected && selected.value !== selected.label && (
        <span className="dropdown__label">{selected.label}</span>
      )}
    </div>
  );
};

export default UiSelect;
