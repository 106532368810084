import React from "react";
import TransactionItem from "./TransactionItem";

export default function Tab({
	transactions = [],
	refetch = () => {},
	isRevoke = false
}) {
	return (
		<>
			{
				transactions.map((transaction) => (
					<TransactionItem
							tx={transaction}
							isRevoke={isRevoke}
							key={`${transaction.multisigAddress}-${transaction.txId}`}
							refetch={refetch}
					/>
				))
			}
		</>
		)
}
