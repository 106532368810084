import { ContractNames } from "@airdao/airdao-node-contracts";
import { ContractNames as BondContractNames } from "@airdao/airdao-bond";

export const contractLabels = {
  [ContractNames.Ecosystem_MasterMultisig]: "Permissions",

  [ContractNames.FinanceMaster]: "Master wallet",
  [ContractNames.FinanceMasterMultisig]: "Master wallet",

  [ContractNames.Ecosystem_BondMarketplaceRewardsBank]: "Legion (Bond)",

  [ContractNames.FinanceRewards]: "Rewards wallet",
  [ContractNames.FinanceRewardsMultisig]: "Rewards wallet",

  [ContractNames.FinanceInvestors]: "Investors wallet",
  [ContractNames.FinanceInvestorsMultisig]: "Investors wallet",

  [ContractNames.FinanceTeam]: "Team wallet",
  [ContractNames.FinanceTeamMultisig]: "Team wallet",

  [ContractNames.FinanceEcosystem]: "Ecosystem wallet",
  [ContractNames.FinanceEcosystemMultisig]: "Ecosystem wallet",

  [ContractNames.FinanceRevenue]: "Revenue wallet",
  [ContractNames.FinanceRevenueMultisig]: "Revenue wallet",

  [ContractNames.Treasury]: "Treasury",
  [ContractNames.TreasuryMultisig]: "Treasury",

  [ContractNames.ValidatorSet]: "Validator Set",
  [ContractNames.ValidatorSetMultisig]: "Validator Set",

  [ContractNames.LegacyPoolManager]: "Legacy Pool Manager",
  [ContractNames.LegacyPoolManagerRewardsBank]: "Legacy Pool RewardsBank",
  [ContractNames.LegacyPoolManagerMultisig]: "Legacy Pool Manager",

  [ContractNames.ServerNodesManager]: "Server Nodes Manager",
  [ContractNames.ServerNodesManagerRewardsBank]: "Server Nodes Rewards Bank",
  [ContractNames.ServerNodesManagerMultisig]: "Server Nodes Manager",

  [ContractNames.BaseNodesManager]: "Base Nodes Manager",
  [ContractNames.BaseNodesManagerRewardsBank]: "Base Nodes Rewards Bank",
  [ContractNames.BaseNodesManagerMultisig]: "Base Nodes Manager",
};

export function getContractLabel(name) {
  return contractLabels[name] || name;
}
