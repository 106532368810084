import UserContext from './context';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import usePermissions from '../PermissionsContext/usePermissions';
import { MULTISIGS } from '@airdao/airdao-node-contracts/dist/src/contracts/names';
import useContracts from '../ContractsContext/useContracts';
import { useNavigate } from 'react-router-dom';

export default function UserContextProvider(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    address: '',
    permissions: [],
  });

  const { users } = usePermissions() || {};
  const { contracts } = useContracts();

  useEffect(() => {
    const { ethereum } = window;

    const handleAccount = () => {
      if (!ethereum || !users || !contracts) return;
      const address = ethers.utils.getAddress(ethereum.selectedAddress);

      try {
        ethereum.enable();
        (async () => {
          const permissions = users[address]?.groups || [];
          setUser({
            address,
            permissions,
            checkIsInitiator: (contractName) =>
              isInitiator(contracts, permissions, contractName),
          });
        })();
      } catch {
        console.error('User denied access to their account');
      }
    };

    handleAccount();
    ethereum.on('accountsChanged', function () {
      navigate('/');
      handleAccount();
    });
  }, [users]);

  return <UserContext.Provider value={user} {...props} />;
}

function isInitiator(contracts, permissions, contractName) {
  const contractAddress = contracts.getContractByName(contractName).address;
  const contractMultisig = MULTISIGS[contractName];
  const contractMultisigAddress = contractMultisig
    ? contracts.getContractByName(contractMultisig).address
    : null;

  return permissions.find(
    ({ address }) =>
      address === contractAddress || address === contractMultisigAddress
  )?.isInitiator;
}
