import React, { useMemo } from "react";
import ApprovedMark from "./ApprovedMark";
import InlineLoader from "./InlineLoader";
import Button from "./Button";
import useUserPermissions from "../../contexts/UserPermissionsContext/useUser";
import { useContractCall } from "../pages/hooks/contractCall";

export function Form({ children, handler, props, contract = null }) {
  const [result, submit] = useContractCall(handler, contract);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  return (
    <form {...props} onSubmit={handleSubmit} className="ui-form">
      {children}
      <div className="form-submit-wrapper">
        <ContractCallResult result={result} />
        {result.isLoading || (
          <Button withArrow className="form-submit">
            {" "}
            Submit{" "}
          </Button>
        )}
      </div>
    </form>
  );
}

export function ContractCallResult({ result, children, show }) {
  if (result.isLoading) return <InlineLoader />;

  if (result.error) {
    let error = result.error.toString();
    if (error.includes("network does not support ENS")) {
      error = "Invalid Address";
    } else if (error.includes(" (arguments")) {
      error = error.split(" (arguments")[0];
    } else if (error.includes(" [")) {
      error = error.split(" [")[0];
    } else if (result.error.reason) {
      error = result.error.reason;
    }
    return <div className="error-message">{error}</div>;
  }
  if (result.result === undefined) return null;

  if (show) return show();
  if (children) return children;
  return <ApprovedMark />;
}
