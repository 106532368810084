import Button from "../../../UI/Button";
import { useState } from "react";
import TransactionDetails from "./TransactionDetails";
import useTransactionActions from "../hooks/useTransactionActions";
import InlineLoader from "../../../UI/InlineLoader";

export default function TransactionItem({
		tx,
		isRevoke = false,
		refetch = () => {},
}) {

	const {
		txId,
		threshold,
		multisigAddress,
		confirmations,
		executed,
		group,
		type,
		parsedCalldata,
		additionalInfo,
		method
	} = tx;

	const [expanded, setExpanded] = useState(false);

	const toggleExpanded = () => setExpanded((prevState) => !prevState)

	const {
		confirmTransaction,
		revokeTransaction,
		isLoading
	} = useTransactionActions(txId, multisigAddress, refetch);

	return (
			<div className={'transaction-item'}>
				<div className={'transaction-item__list'}>
					<p className={'transaction-item__mark'} style={{ background: colorByType[type] }}>
						{type}
					</p>
					{
						group &&
							<div className={'transaction-item__list-item'}>
								<span className={'transaction-item__title'}>Group: </span>
								<span className={'transaction-item__value'}>{ group }</span>
							</div>
					}
					<div className={'transaction-item__list-item'}>
						<span className={'transaction-item__title'}>Tx ID: </span>
						<span className={'transaction-item__value'}>{ txId.toString() }</span>
					</div>

					{
						method &&
							<div className={'transaction-item__list-item'}>
								<span className={'transaction-item__title'}>Method: </span>
								<span className={'transaction-item__value'}>{ method }</span>
							</div>
					}

					{
						additionalInfo &&
							Object.keys(additionalInfo).map((key) => (
									<div className={'transaction-item__list-item'}>
										<span className={'transaction-item__title'}>{ key }: </span>
										<span className={'transaction-item__value'}>{ additionalInfo[key] }</span>
									</div>
							))
					}

					{
							!expanded &&
							<div className='transaction-item__linkish' onClick={toggleExpanded}>
								Show details
							</div>
					}
					{
							expanded &&
							<TransactionDetails
									{...{
										executed,
										confirmations,
										parsedCalldata,
									}}
							/>
					}
					{
							expanded &&
							<div className='transaction-item__linkish' onClick={toggleExpanded}>
								Hide details
							</div>
					}
				</div>
				<div className='transaction-item__footer'>
					<div className={'transaction-item__approvals-amount'}>
						Approvals: {confirmations.length} of { threshold }
					</div>

					{
						!isLoading ?
								<Button
										className={'transaction-item__button'}
										withArrow
										onClick={isRevoke ? revokeTransaction : confirmTransaction}
								>
									{ isRevoke ? 'Revoke' : 'Confirm' }
								</Button>
								:
								<InlineLoader />
					}
				</div>
			</div>
	)
}

const colorByType = {
	'Permissions': '#8537f2',
	'Core': '#16C784',
	'Finance': '#F2C037',
}
