import s from './heading.module.css';

const Heading = ({ title, label }) => {
  if (!title) return null;
  return (
    <div className={s.heading}>
      <h3>{title}</h3>
      <span>{label}</span>
    </div>
  );
};

export default Heading;
