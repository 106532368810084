export default {
  StarfleetStaking_RewardsBank: {
    30746: {
      address: "0x0130d99d07Fc75FABF8fd656164cc50b14279302",
      abi: [
        "constructor()",
        "event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)",
        "event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)",
        "event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)",
        "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
        "function getRoleAdmin(bytes32 role) view returns (bytes32)",
        "function grantRole(bytes32 role, address account)",
        "function hasRole(bytes32 role, address account) view returns (bool)",
        "function renounceRole(bytes32 role, address account)",
        "function revokeRole(bytes32 role, address account)",
        "function supportsInterface(bytes4 interfaceId) view returns (bool)",
        "function withdrawAmb(address addressTo, uint256 amount)",
        "function withdrawErc20(address tokenAddress, address addressTo, uint256 amount)",
      ],
      deployTx:
        "0x2716daa18908b5a695534955229138712b19b803722a1b9963819636b5b403a1",
      fullyQualifiedName: "contracts/funds/RewardsBank.sol:RewardsBank",
    },
    16718: {
      address: "0xcF44A90C31EFbaD8d8030699819c6e14dc0D9E31",
      abi: [
        "constructor()",
        "event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)",
        "event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)",
        "event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)",
        "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
        "function getRoleAdmin(bytes32 role) view returns (bytes32)",
        "function grantRole(bytes32 role, address account)",
        "function hasRole(bytes32 role, address account) view returns (bool)",
        "function renounceRole(bytes32 role, address account)",
        "function revokeRole(bytes32 role, address account)",
        "function supportsInterface(bytes4 interfaceId) view returns (bool)",
        "function withdrawAmb(address addressTo, uint256 amount)",
        "function withdrawErc20(address tokenAddress, address addressTo, uint256 amount)",
      ],
      deployTx:
        "0xddd581fca336cf60116ddac8f5cd4e5bed94af50bf8b240536eb57f48153a687",
      fullyQualifiedName: "contracts/funds/RewardsBank.sol:RewardsBank",
    },
  },
  StarfleetStaking: {
    30746: {
      address: "0x021de22a8b1B021f07a94B047AA557079BbCa6ed",
      abi: [
        "constructor(string name_, address owner, address rewardsBank, uint256 timestampDayA, uint256 profitability, uint256 unstakePeriod, uint256 minStake)",
        "error AlreadyClaimed()",
        "error AlreadyUnstaked()",
        "error NotStaked()",
        "error NotUnstaked()",
        "error StakeNotAllowed()",
        "error StakeTooLow()",
        "error UnstakeNotAllowed()",
        "error UnstakingInProgress()",
        "event InsufficientBalance(address account, uint256 amount)",
        "event Profitability(uint256 profitability)",
        "event Release(address indexed account, uint256 stake)",
        "event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)",
        "event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)",
        "event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)",
        "event Stake(address indexed account, uint256 stake)",
        "event Unstake(address indexed account, uint256 stake, uint256 until)",
        "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
        "function VERSION() view returns (uint256)",
        "function available() view returns (uint256)",
        "function distributeRewards()",
        "function getBank() view returns (address)",
        "function getDayA() view returns (uint256)",
        "function getMinStake() view returns (uint256)",
        "function getMultiplicator(address account) view returns (uint256)",
        "function getProfitability() view returns (uint256)",
        "function getRoleAdmin(bytes32 role) view returns (bytes32)",
        "function getStakerInfo(address staker) view returns (uint256, uint256, uint256, uint256, uint256)",
        "function getStakersCount() view returns (uint256)",
        "function getTotalStake() view returns (uint256)",
        "function getUnstakePeriod() view returns (uint256)",
        "function grantRole(bytes32 role, address account)",
        "function hasRole(bytes32 role, address account) view returns (bool)",
        "function name() view returns (string)",
        "function release()",
        "function renounceRole(bytes32 role, address account)",
        "function revokeRole(bytes32 role, address account)",
        "function rewardOf(address account) view returns (uint256)",
        "function setDayA(uint256 timestamp)",
        "function setMinStake(uint256 value)",
        "function setProfitability(uint256 profitability)",
        "function setUnstakePeriod(uint256 period)",
        "function stake() payable",
        "function stakeOf(address account) view returns (uint256)",
        "function supportsInterface(bytes4 interfaceId) view returns (bool)",
        "function unstake()",
        "function withdraw(address to)",
      ],
      deployTx:
        "0xf759aee4246b622c72e0cd80c377c2ca7ef829b8376f412b4889fef51a89a25b",
      fullyQualifiedName: "contracts/StarfleetStaking.sol:StarfleetStaking",
    },
    16718: {
      address: "0x4d43802C8653c3D3257A6344428a9488C8FE780d",
      abi: [
        "constructor(string name_, address owner, address rewardsBank, uint256 timestampDayA, uint256 profitability, uint256 unstakePeriod, uint256 minStake)",
        "error AlreadyClaimed()",
        "error AlreadyUnstaked()",
        "error NotStaked()",
        "error NotUnstaked()",
        "error StakeNotAllowed()",
        "error StakeTooLow()",
        "error UnstakeNotAllowed()",
        "error UnstakingInProgress()",
        "event InsufficientBalance(address account, uint256 amount)",
        "event Profitability(uint256 profitability)",
        "event Release(address indexed account, uint256 stake)",
        "event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)",
        "event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)",
        "event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)",
        "event Stake(address indexed account, uint256 stake)",
        "event Unstake(address indexed account, uint256 stake, uint256 until)",
        "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
        "function VERSION() view returns (uint256)",
        "function available() view returns (uint256)",
        "function distributeRewards()",
        "function getBank() view returns (address)",
        "function getDayA() view returns (uint256)",
        "function getMinStake() view returns (uint256)",
        "function getMultiplicator(address account) view returns (uint256)",
        "function getProfitability() view returns (uint256)",
        "function getRoleAdmin(bytes32 role) view returns (bytes32)",
        "function getStakerInfo(address staker) view returns (uint256, uint256, uint256, uint256, uint256)",
        "function getStakersCount() view returns (uint256)",
        "function getTotalStake() view returns (uint256)",
        "function getUnstakePeriod() view returns (uint256)",
        "function grantRole(bytes32 role, address account)",
        "function hasRole(bytes32 role, address account) view returns (bool)",
        "function name() view returns (string)",
        "function release()",
        "function renounceRole(bytes32 role, address account)",
        "function revokeRole(bytes32 role, address account)",
        "function rewardOf(address account) view returns (uint256)",
        "function setDayA(uint256 timestamp)",
        "function setMinStake(uint256 value)",
        "function setProfitability(uint256 profitability)",
        "function setUnstakePeriod(uint256 period)",
        "function stake() payable",
        "function stakeOf(address account) view returns (uint256)",
        "function supportsInterface(bytes4 interfaceId) view returns (bool)",
        "function unstake()",
        "function withdraw(address to)",
      ],
      deployTx:
        "0x364ff7636a25bc5010355cf874e4fa1a854a492de0a42243a33ed8cf27933a7b",
      fullyQualifiedName: "contracts/StarfleetStaking.sol:StarfleetStaking",
    },
  },
};
