import useContracts from "../../../contexts/ContractsContext/useContracts";
import Heading from "../../UI/Heading";
import InlineLoader from "../../UI/InlineLoader";
import Table from "../../UI/Table";
import tableColumns from "./tableColumns";
import { useEffect, useState } from "react";
import { generalBankTokens, HBRAddresses } from "../../../utils/constants";
import { ethers } from "ethers";
import erc20abi from "../../../utils/abi/erc20token";
import { ContractNames } from "@airdao/airdao-node-contracts";

const Banks = () => {
  const { chainId, provider, contracts } = useContracts();

  const generalBankContract = contracts.getContractByName(
    ContractNames.Ecosystem_GeneralTokenBank,
  );

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getTableData().then((res) => setTableData(res));
  }, []);

  const getTableData = async () => {
    return await Promise.all(
      generalBankTokens.map(async (token) => {
        const tokenData = { ...token };
        if (token.symbol === "AMB") {
          tokenData.balance = await provider.getBalance(
            generalBankContract.address,
          );
        } else {
          const tokenContract = new ethers.Contract(
            token.address[chainId],
            erc20abi,
            provider,
          );
          tokenData.balance = await tokenContract.balanceOf(
            generalBankContract.address,
          );
        }
        return tokenData;
      }),
    );
  };

  return (
    <>
      <div className="page page_wide">
        <Heading title="Banks" label={generalBankContract.address} />
        <Table data={tableData} columns={tableColumns} />
      </div>
    </>
  );
};

export default Banks;
