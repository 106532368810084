/*
Copyright: Ambrosus Inc.
Email: tech@ambrosus.io

This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0. If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.

This Source Code Form is “Incompatible With Secondary Licenses”, as defined by the Mozilla Public License, v. 2.0.
*/

import React from "react";
import { BrowserRouter } from "react-router-dom";
import "w3-css/w3.css";
import { QueryClient, QueryClientProvider } from "react-query";
import ContractsContextProvider from "../contexts/ContractsContext/provider";
import UserContextProvider from "../contexts/UserPermissionsContext/provider";
import Layout from "./Layout";
import PermissionsContextProvider from "../contexts/PermissionsContext/provider";

const queryClient = new QueryClient();
console.log(1);
export default function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ContractsContextProvider>
          <PermissionsContextProvider>
            <UserContextProvider>
              <Layout />
            </UserContextProvider>
          </PermissionsContextProvider>
        </ContractsContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
