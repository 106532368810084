import { useState } from "react";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import {
  discountFeederMethods,
  rewardsFeederMethods,
} from "../../../../utils/constants";
import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { ContractNames } from "@airdao/bond-periphery";
import { Form } from "../../../UI/Form";
import { Input } from "../../../UI/Inputs";
import { submitBondMarketplaceMultisigTx } from "../../../../utils/submitBondMarketplaceMultisigTx";
import { constants, utils } from "ethers";

const tabs = ["discount", "rewards"];

const Feeder = () => {
  const { peripheryContracts, contracts } = useContracts();

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [tierLevel, setTierLevel] = useState("");
  const [tierParams, setTierParams] = useState({
    rewardAPY: "",
    tierMultiplier: "",
  });
  const [discountData, setDiscountData] = useState({
    tierLevel: "",
    discount: "",
  });

  const discountFeeder = peripheryContracts.getContractByName(
    ContractNames.DiscountFeeder,
  );
  const rewardFeeder = peripheryContracts.getContractByName(
    ContractNames.RewardFeeder,
  );
  const kosContract = peripheryContracts.getContractByName(
    ContractNames.StakingToken,
  );

  const handleParams = (e) => {
    const { value, name } = e.target;
    setTierParams((state) => ({
      ...state,
      [name]: value,
    }));
  };
  const setRewards = async () => {
    const decimals = await rewardFeeder.decimals();
    const { data } = await rewardFeeder.populateTransaction.setTierRewards(
      kosContract.address,
      tierLevel,
      {
        rewardAPY: utils.parseUnits(
          (+tierParams.rewardAPY / 100).toString(),
          decimals,
        ),
        tierMultiplier: utils.parseUnits(tierParams.tierMultiplier, 18),
      },
    );
    const tx = await submitBondMarketplaceMultisigTx(
      contracts,
      rewardFeeder.address,
      data,
    );
    return await tx.wait();
  };

  const handleDiscount = async () => {
    const decimals = await discountFeeder.decimals();
    const { data } = await discountFeeder.populateTransaction.setDiscount(
      kosContract.address,
      discountData.tierLevel,
      utils.parseUnits(discountData.discount, decimals - 2),
    );
    const tx = await submitBondMarketplaceMultisigTx(
      contracts,
      discountFeeder.address,
      data,
    );
    return await tx.wait();
  };

  const handleDiscountData = (event) => {
    const { name, value } = event.target;

    setDiscountData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="page page_wide tabs-heading">
        {tabs.map((el) => (
          <button
            key={el}
            type="button"
            onClick={() => setSelectedTab(el)}
            className={`tabs-btn capitalized ${selectedTab === el ? "tabs-btn_selected" : ""}`}
          >
            {el}
          </button>
        ))}
      </div>
      {selectedTab === "discount" ? (
        <ContractMethodsForm
          key="discount"
          label={ContractNames.DiscountFeeder}
          methods={discountFeederMethods}
          contract={peripheryContracts.getContractByName(
            ContractNames.DiscountFeeder,
          )}
        >
          <Form handler={handleDiscount} contract={discountFeeder}>
            <p>Method: setDiscount</p>
            <Input
              value={discountData.tierLevel}
              setValue={handleDiscountData}
              title="Tier level"
              placeholder="0"
              name="tierLevel"
              returnEvent
            />
            <Input
              value={discountData.discount}
              setValue={handleDiscountData}
              title="Discount"
              placeholder="0%"
              name="discount"
              returnEvent
            />
          </Form>
        </ContractMethodsForm>
      ) : (
        <ContractMethodsForm
          label={ContractNames.RewardFeeder}
          methods={rewardsFeederMethods}
          contract={peripheryContracts.getContractByName(
            ContractNames.RewardFeeder,
          )}
        >
          <Form handler={setRewards} contract={rewardFeeder}>
            <h3>Method: setTierRewards</h3>
            <Input
              value={tierLevel}
              setValue={setTierLevel}
              title="Tier level"
              placeholder="0"
            />
            <Input
              value={tierParams.rewardAPY}
              setValue={handleParams}
              returnEvent
              name="rewardAPY"
              title="RewardAPY in %"
              placeholder="0%"
            />
            <Input
              value={tierParams.tierMultiplier}
              setValue={handleParams}
              returnEvent
              name="tierMultiplier"
              title="Tier multiplier"
              placeholder="0"
            />
          </Form>
        </ContractMethodsForm>
      )}
    </>
  );
};

export default Feeder;
