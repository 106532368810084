import { BigNumber, utils, constants } from "ethers";
import { useState } from "react";
import { Methods, ContractNames } from "@airdao/airdao-node-contracts";
import useContracts from "../../contexts/ContractsContext/useContracts";
import { delay, displayAmount, isValidAmount } from "../../utils";

const useClaimFeesHandler = (row) => {
  const { contracts, getBalances } = useContracts();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const balanceFrom = BigNumber.from(row.balance);
  const formatAmount = displayAmount(
    +utils.formatUnits(balanceFrom, row.decimals),
    6,
  );
  const [addressTo, setAddressTo] = useState("");
  const [amount, setAmount] = useState(formatAmount);

  const isDisabled = () => {
    if (
      !addressTo ||
      !utils.isAddress(addressTo) ||
      !isValidAmount(amount) ||
      Number(amount) > formatAmount
    ) {
      return true;
    }

    return false;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (row.contractAddress === constants.AddressZero) {
        const tx = await Methods.rewardsBanksWithdrawAmb(
          contracts,
          ContractNames.Ecosystem_BondMarketplaceRewardsBank,
          addressTo,
          utils.parseEther(amount.toString()),
        );
        await tx.wait();
      } else {
        const tx = await Methods.rewardsBanksWithdrawTokens(
          contracts,
          ContractNames.Ecosystem_BondMarketplaceRewardsBank,
          row.contractAddress,
          addressTo,
          utils.parseEther(amount.toString()),
        );
        await tx.wait();
      }
      await getBalances();
    } catch (e) {
      console.error("Error while call rewardsBanksWithdrawTokens: ", e);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return {
    isLoading,
    isModalOpen,
    handleClose,
    handleOpen,
    handleSubmit,
    setAddressTo,
    setAmount,
    amount,
    addressTo,
    isDisabled,
  };
};

export default useClaimFeesHandler;
