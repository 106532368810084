import {
  engagementDiscountTierManagerMethods,
  engagementOracleMethods,
  rewardVaultMethods,
  timeframeOracleMethods,
} from "../../../../utils/constants";
import { ContractNames } from "@airdao/bond-periphery";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { Input } from "../../../UI/Inputs";
import { Form } from "../../../UI/Form";
import { useEffect, useMemo, useRef, useState } from "react";
import { submitBondMarketplaceMultisigTx } from "../../../../utils/submitBondMarketplaceMultisigTx";
import { BigNumber, utils } from "ethers";
import Datetime from "react-datetime";
import moment from "moment/moment";

const Engagement = () => {
  const { peripheryContracts, contracts } = useContracts();

  const decimals = useRef(null);

  const [tiers, setTiers] = useState("");
  const [tier, setTier] = useState({
    level: "",
    discount: "",
  });
  const [frameDates, setFrameDates] = useState({
    startTime: "",
    endTime: "",
  });

  const contract = peripheryContracts.getContractByName(
    ContractNames.EngagementDiscountTierManager,
  );
  const oracleContract = peripheryContracts.getContractByName(
    ContractNames.TimeFrameOracle,
  );

  useEffect(() => {
    contract.decimals().then((res) => (decimals.current = res));
  }, []);

  const handleTier = (event) => {
    const { name, value } = event.target;
    setTier((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleDate = (value, name) => {
    setFrameDates((state) => ({
      ...state,
      [name]: moment(value).unix().toString(),
    }));
  };

  const formatDiscount = (discount) =>
    utils.parseUnits(discount, decimals.current).div(BigNumber.from("100"));

  const submitTier = async () => {
    const { data } = await contract.populateTransaction.setTier(tier.level, {
      discount: formatDiscount(tier.discount),
    });
    const tx = await submitBondMarketplaceMultisigTx(
      contracts,
      contract.address,
      data,
    );
    return await tx.wait();
  };

  const submitTiers = async () => {
    const arr = tiers.split(",");
    const param = arr.map((el) => ({ discount: formatDiscount(el) }));

    const { data } = await contract.populateTransaction.setTiers(param);
    const tx = await submitBondMarketplaceMultisigTx(
      contracts,
      contract.address,
      data,
    );
    return await tx.wait();
  };

  const submitFrame = async () => {
    const { data } =
      await oracleContract.populateTransaction.setFrame(frameDates);

    const tx = await submitBondMarketplaceMultisigTx(
      contracts,
      oracleContract.address,
      data,
    );
    return await tx.wait();
  };

  return (
    <>
      <ContractMethodsForm
        contract={peripheryContracts.getContractByName(
          ContractNames.TimeFrameOracle,
        )}
        methods={timeframeOracleMethods}
        label={ContractNames.TimeFrameOracle}
      >
        <Form handler={submitFrame} contract={oracleContract}>
          <p>Method: setFrame</p>
          <p>Start time:</p>
          <Datetime
            onChange={(value) => handleDate(value, "startTime")}
            dateFormat="DD/MM/YYYY"
            timeFormat="HH:mm"
          />
          <p>End time:</p>
          <Datetime
            onChange={(value) => handleDate(value, "endTime")}
            dateFormat="DD/MM/YYYY"
            timeFormat="HH:mm"
          />
        </Form>
      </ContractMethodsForm>
      <ContractMethodsForm
        contract={peripheryContracts.getContractByName(
          ContractNames.EngagementOracle,
        )}
        methods={engagementOracleMethods}
        label={ContractNames.EngagementOracle}
      />
      <ContractMethodsForm
        contract={peripheryContracts.getContractByName(
          ContractNames.EngagementDiscountTierManager,
        )}
        methods={engagementDiscountTierManagerMethods}
        label={ContractNames.EngagementDiscountTierManager}
      >
        <Form handler={submitTiers} contract={contract}>
          <p>Method: setTiers</p>
          <Input
            title="Tiers"
            setValue={setTiers}
            value={tiers}
            placeholder="Enter values separated by commas: 1,2,3"
          />
        </Form>
        <Form handler={submitTier} contract={contract}>
          <p>Method: setTier</p>
          <Input
            title="Level"
            name="level"
            setValue={handleTier}
            returnEvent
            value={tier.level}
          />
          <Input
            title="Discount"
            name="discount"
            setValue={handleTier}
            returnEvent
            value={tier.discount}
          />
        </Form>
      </ContractMethodsForm>
    </>
  );
};

export default Engagement;
