import useContracts from "../../../../contexts/ContractsContext/useContracts";
import Table from "../../../UI/Table";

import { withDrawColumns } from "../../../UI/Table/columns";
import InlineLoader from "../../../UI/InlineLoader";
import Heading from "../../../UI/Heading";
// import ChangeWallet from './ChangeWallet';

const Bond = () => {
  const { address, isLoading, feeBalances } = useContracts();
  console.log(feeBalances);
  return (
    <>
      <div className="page page_wide">
        <Heading title="Legion (Bond)" label={address} />
        {isLoading || feeBalances.length === 0 ? (
          <InlineLoader />
        ) : (
          <Table data={feeBalances} columns={withDrawColumns} />
        )}
      </div>
      {/* <ChangeWallet /> */}
    </>
  );
};

export default Bond;
