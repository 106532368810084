import { useEffect, useState } from "react";
import { utils } from "ethers";
import useContracts from "../../../contexts/ContractsContext/useContracts";

const useStakingContractData = (contract) => {
  const { provider } = useContracts();

  const [stakingData, setStakingData] = useState({});
  const [balance, setBalance] = useState("0");

  useEffect(() => {
    contract.getProfitability().then((res) => {
      handleStakingData({ profitability: utils.formatUnits(res, 4) });
    });
    contract.getUnstakePeriod().then((res) => {
      handleStakingData({ delay: utils.formatUnits(res, 0) });
    });
    contract.getDayA().then((res) => {
      handleStakingData({ date: utils.formatUnits(res, 0) });
    });

    provider.getBalance(contract.address).then(setBalance);
  }, []);

  const handleStakingData = (data) => {
    setStakingData((state) => ({ ...state, ...data }));
  };

  return { stakingData, balance };
};

export default useStakingContractData;
