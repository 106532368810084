import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { constants, ethers, utils } from "ethers";
import abi from "../../../../utils/abi/astra";
import erc20abi from "../../../../utils/abi/erc20token";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { astraEclipseMethods } from "../../../../utils/constants";
import { useEffect, useRef, useState } from "react";
import { submitAstraMultisigTx } from "../../../../utils/submitAstraMultisigTx";
import { Form } from "../../../UI/Form";
import { Input } from "../../../UI/Inputs";
import Treshold from "./Treshold";
import Math from "./Math";
import PairManager from "./PairManager";

const tabs = ["Pool manager", "Math", "Other"];

const Eclipse = () => {
  const { provider, chainId } = useContracts();

  const contract = useRef(
    new ethers.Contract(
      abi[chainId].EclipseManager.address,
      abi[chainId].EclipseManager.abi,
      provider.getSigner(),
    ),
  );

  const [tab, setTab] = useState(tabs[0]);

  return (
    <>
      <div className="page page_wide tabs-heading">
        {tabs.map((el) => (
          <button
            key={el}
            type="button"
            onClick={() => setTab(el)}
            className={`tabs-btn capitalized ${tab === el ? "tabs-btn_selected" : ""}`}
          >
            {el}
          </button>
        ))}
      </div>
      {tab === "Pool manager" && <PairManager contract={contract.current} />}
      {tab === "Math" && <Math contract={contract.current} />}
      {tab === "Other" && (
        <ContractMethodsForm
          multisig={ContractNames.Ecosystem_AstradexMultisig}
          contract={contract.current}
          methods={astraEclipseMethods}
          label="Eclipse"
        />
      )}
    </>
  );
};

export default Eclipse;
