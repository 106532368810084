import Tabs from "./Tabs";
import React, {useState} from "react";
import Tab from "./components/Tab";
import useTransactions from "./hooks/useTransactions";
import InlineLoader from "../../UI/InlineLoader";
import {useLocation} from "react-router-dom";

const TransactionsList = () => {
  const { hash } = useLocation();
  const [activeTab, setActiveTab] = useState(
    hash.replace('#', '') || 'confirmation'
  );

  const {
    refetch,
    data: {pending, approved},
    isFetching
  } = useTransactions();

  return (
    <div className="page transactions-page">
      <div className='transactions-page__header'>
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab}/>
        {isFetching && <InlineLoader/>}
      </div>

      <div className="confirmations">
        {activeTab === "confirmation" && <Tab transactions={pending} refetch={refetch}/>}
        {activeTab === "revoke" && <Tab transactions={approved} refetch={refetch} isRevoke={true}/>}
      </div>
    </div>
  );
}

export default TransactionsList;
