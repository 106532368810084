import useContracts from "../../../../../contexts/ContractsContext/useContracts";
import { Input } from "../../../../UI/Inputs";
import { Form } from "../../../../UI/Form";
import { useEffect, useState } from "react";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { utils } from "ethers";
import { submitLiquidStakingMultisigTx } from "../../../../../utils/submitLiquidStakingMultisigTx";

const ManageAPR = () => {
  const { contracts } = useContracts();

  const [formData, setFormData] = useState({
    percent: "",
  });

  const [currentInterest, setCurrentInterest] = useState("");

  const contract = contracts.getContractByName(
    ContractNames.Ecosystem_LiquidPool,
  );

  const init = async () => {
    const interestPeriodNumber = +utils.formatUnits(
      await contract.interestPeriod(),
      0,
    );
    const interest = +utils.formatUnits(await contract.interest(), 0);
    const r = interest / 1000000000;
    const periodsPerYear = ((365 * 24 * 60 * 60) / interestPeriodNumber) * r;
    const periodsPerYearRounded = Math.ceil(periodsPerYear * 10000) / 100;

    setCurrentInterest(periodsPerYearRounded);
  };
  useEffect(() => {
    init();
  }, []);

  const handleData = (event) => {
    const { value, name } = event.target;
    if (+value <= 100) {
      setFormData({ percent: value });
    }
  };

  const handleAPR = async () => {
    const { data } = await contract.populateTransaction.setInterest(
      Math.floor((+formData.percent * 10000000) / (365 * 24)),
      3600,
    );

    const tx = await submitLiquidStakingMultisigTx(
      contracts,
      contract.address,
      data,
    );
    return await tx.wait();
  };

  return (
    <div className="page">
      <Form handler={handleAPR} contract={contract}>
        <h3>Manage APY</h3>
        <div>
          <p>
            Current APY: <b>{currentInterest}%</b>
          </p>
        </div>
        <Input
          type="number"
          value={formData.percent}
          setValue={handleData}
          name="percent"
          title="APY"
          placeholder="0%"
          returnEvent
        />
      </Form>
    </div>
  );
};

export default ManageAPR;
