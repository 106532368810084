import React from 'react';

const InlineLoader = () => (
  <div className='inline-loader'>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default InlineLoader;
