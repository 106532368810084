import ManageAPR from "./components/ManageAPR";
import StorageRewards from "./components/StorageRewards";
import WithdrawalDelay from "./components/WithdrawalDelay";
import MinStake from "./components/MinStake";
import NodesQuantity from "./components/NodesQuantity";
import Treasury from "./components/Treasury";
import { ContractNames } from "@airdao/airdao-node-contracts";

const LiquidStaking = () => {
  return (
    <div>
      <ManageAPR />
      <StorageRewards
        contract={ContractNames.Ecosystem_LiquidPoolRewardsBank}
        label="Rewards bank (users)"
        multisigName={ContractNames.Ecosystem_LiquidPoolMultisig}
      />
      <StorageRewards
        contract={ContractNames.Ecosystem_LiquidNodesManagerRewardsBank}
        label="Rewards bank (nodes)"
        multisigName={ContractNames.Ecosystem_LiquidPoolMultisig}
      />
      <NodesQuantity />
      <WithdrawalDelay />
      <MinStake />
      <Treasury />
    </div>
  );
};

export default LiquidStaking;
