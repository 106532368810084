/* eslint-disable react-hooks/rules-of-hooks */
import { BigNumber, utils } from "ethers";
import { createColumnHelper } from "@tanstack/react-table";
import { displayAmount } from "../../../utils";
import WithdrawModal from "./WithdrawModal";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.display({
    id: "token",
    header: () => <div className="header-cell">Token</div>,
    cell: (info) => {
      const row = info.row.original;
      return (
        <div className="header-cell">
          <span className="header-cell gap-2">
            <img src={row.logoUrl} width={24} height={24} alt={row.symbol} />
            <span className="uppercase">{row.symbol}</span>
          </span>
        </div>
      );
    },
  }),
  columnHelper.display({
    id: "balance",
    header: () => <div className="header-cell">Available For Withdrawal</div>,
    cell: (info) => {
      const row = info.row.original;
      const balanceFrom = BigNumber.from(row.balance);
      const formatAmount = displayAmount(
        +utils.formatUnits(balanceFrom, row.decimals),
        6,
      );

      return (
        <div className="header-cell">
          <span className="text-left mr-1">{formatAmount}</span>
          <span className="text-left">{row.symbol}</span>
        </div>
      );
    },
  }),
  columnHelper.display({
    id: "actions",
    header: () => <div className="header-cell">Action</div>,
    cell: (info) => {
      const row = info.row.original;
      return (
        <div className="header-cell">
          <WithdrawModal token={row} />
        </div>
      );
    },
  }),
];

export default columns;
