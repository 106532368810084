import React from 'react';
import {useNavigate} from "react-router-dom";

const tabs = [
  'confirmation',
  'revoke',
]

const Tabs = ({activeTab, setActiveTab}) => {
  const navigate = useNavigate();

  const handleTab = (name) => {
    navigate(`#${name}`);
    setActiveTab(name);
  }
  return (
    <div className="confirmation-tabs">
      {tabs.map((name) => (
        <button
          key={name}
          className={`confirmation-tabs__btn ${activeTab === name ? 'confirmation-tabs__btn_selected' : ''}`}
          type="button"
          onClick={() => handleTab(name)}
        >
          {name}
        </button>
      ))}
    </div>
  );
}

export default Tabs;
