import { Multisig } from '@airdao/airdao-node-contracts';
import { useMemo, useState } from 'react';
import useContracts from '../../../../contexts/ContractsContext/useContracts';

export default function useTransactionActions(txId, multisigAddress, refetch) {
  const { contracts } = useContracts();
  const [isLoading, setIsLoading] = useState(false);

  const confirmTransaction = useMemo(
    () => async () => {
      try {
        setIsLoading(true);
        const { wait } = await Multisig.confirm(
          contracts.getContractByAddress(multisigAddress),
          txId
        );
        await wait();
        refetch();
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    },
    [refetch, txId, multisigAddress, contracts]
  );

  const revokeTransaction = useMemo(
    () => async () => {
      try {
        setIsLoading(true);
        const { wait } = await Multisig.revokeConfirm(
          contracts.getContractByAddress(multisigAddress),
          txId
        );
        await wait();
        refetch();
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    },
    [refetch, txId, multisigAddress, contracts]
  );

  return { confirmTransaction, revokeTransaction, isLoading };
}
