import useContracts from "../../../../../contexts/ContractsContext/useContracts";
import ContractMethodsForm from "../../../../UI/ContractMethodsForm";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { liquidStakingSetMinStake } from "../../../../../utils/constants";
import { useEffect, useState } from "react";
import { utils } from "ethers";

const MinStake = () => {
  const { contracts } = useContracts();

  const [currentMinStake, setCurrentMinStake] = useState("");

  const contract = contracts.getContractByName(
    ContractNames.Ecosystem_LiquidPool,
  );

  useEffect(() => {
    contract
      .minStakeValue()
      .then((res) => setCurrentMinStake(utils.formatEther(res)));
  }, []);

  return (
    <ContractMethodsForm
      label="Min stake amount"
      contract={contract}
      methods={liquidStakingSetMinStake}
      multisig={ContractNames.Ecosystem_LiquidPoolMultisig}
    >
      <p>
        Current min stake: <b>{currentMinStake} AMB</b>
      </p>
    </ContractMethodsForm>
  );
};

export default MinStake;
