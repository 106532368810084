import Button from "../../UI/Button";
import Modal from "../../UI/Modal";
import { InputAddress, InputAmount } from "../../UI/Inputs";
import {useEffect, useState} from "react";
import { Form } from "../../UI/Form";
import { ContractNames } from "@airdao/airdao-node-contracts";
import useContracts from "../../../contexts/ContractsContext/useContracts";
import { utils } from "ethers";
import { submitLiquidStakingMultisigTx } from "../../../utils/submitLiquidStakingMultisigTx";
import { submitTransaction } from "../../../utils/submitTransaction";

const WithdrawModal = ({ token }) => {
  const { contracts, chainId } = useContracts();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressTo, setAddressTo] = useState("");
  const [amount, setAmount] = useState("");

  const generalBankContract = contracts.getContractByName(
    ContractNames.Ecosystem_GeneralTokenBank,
  );
  const generalBankMultisigContract = contracts.getContractByName(
    ContractNames.Ecosystem_GeneralTokenBank_Multisig,
  );

  useEffect(() => {
    setAmount('');
    setAddressTo('');
  }, [isModalOpen]);

  const submitMultisig = async (calldata) => {
    const tx = await submitTransaction(
      generalBankMultisigContract,
      generalBankContract.address,
      0,
      calldata,
    );
    return await tx.wait();
  };

  const handleSubmit = async () => {
    let data;
    if (token.symbol === "AMB") {
      data = (
        await generalBankContract.populateTransaction.withdrawAmb(
          addressTo,
          utils.parseEther(amount),
        )
      ).data;
    } else {
      data = (
        await generalBankContract.populateTransaction.withdrawErc20(
          token.address[chainId],
          addressTo,
          utils.parseEther(amount),
        )
      ).data;
    }

    return await submitMultisig(data);
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>Withdraw</Button>
      {isModalOpen && (
        <Modal handleClose={() => setIsModalOpen(false)}>
          <Form
            handler={handleSubmit}
            contract={generalBankContract}
            className="form-box"
          >
            <InputAddress
              title={"Address to"}
              value={addressTo}
              setValue={setAddressTo}
            />
            <InputAmount
              title={"Amount"}
              value={amount}
              setValue={setAmount}
              inputProps={{ placeholder: '0' }}
            />
          </Form>
        </Modal>
      )}
    </>
  );
};

export default WithdrawModal;
