import useContracts from "../../../../contexts/ContractsContext/useContracts";
import ContractMethodsForm from "../../../UI/ContractMethodsForm";
import { stakingTierManagerMethods } from "../../../../utils/constants";
import { ContractNames } from "@airdao/bond-periphery";
import { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import { Input } from "../../../UI/Inputs";
import { Form } from "../../../UI/Form";
import closeIcon from "../../../../assets/svg/close.svg";
import { submitBondMarketplaceMultisigTx } from "../../../../utils/submitBondMarketplaceMultisigTx";

const Tiers = () => {
  const { peripheryContracts, contracts } = useContracts();
  const [tiers, setTiers] = useState([]);
  const [tiersUpdateData, setTiersUpdateData] = useState({});
  const [newTierMinStake, setNewTierMinStake] = useState("");

  const tWSContract = peripheryContracts.getContractByName(
    ContractNames.TWStakingTierManager,
  );
  const kosToken = peripheryContracts.getContractByName(
    ContractNames.StakingToken,
  );

  useEffect(() => {
    tWSContract.stakingTiers(kosToken.address).then((res) => {
      setTiers(
        res
          .map(({ level, minStaked }) => ({
            level: ethers.utils.formatUnits(level, 0),
            minStaked: ethers.utils.formatUnits(minStaked, 18),
          }))
          .filter(({ level }) => level !== "0"),
      );
    });
  }, []);

  useEffect(() => {
    const obj = {};
    tiers.forEach(({ level, minStaked }) => (obj[level] = minStaked));
    setTiersUpdateData(obj);
  }, [tiers]);

  const handleTiersUpdateData = (level, minStaked) => {
    setTiersUpdateData((state) => ({
      ...state,
      [level]: minStaked,
    }));
  };

  const updateTiers = async (formattedTiers) => {
    const { data } = await tWSContract.populateTransaction.setTokenTiers(
      kosToken.address,
      formattedTiers,
    );
    const tx = await submitBondMarketplaceMultisigTx(
      contracts,
      tWSContract.address,
      data,
    );
    return await tx.wait();
  };

  const changeTiers = async () => {
    const formattedTiers = Object.keys(tiersUpdateData).map((el) => ({
      minStaked: ethers.utils.parseUnits(tiersUpdateData[el], 18),
    }));
    return await updateTiers(formattedTiers);
  };

  const deleteTiers = async (level) => {
    return await updateTiers(
      tiers.slice(0, +level - 1).map(({ minStaked }) => ({
        minStaked: ethers.utils.parseUnits(minStaked, 18),
      })),
    );
  };

  const addTier = async () => {
    return await updateTiers(
      [...tiers, { minStaked: newTierMinStake }].map(({ minStaked }) => ({
        minStaked: ethers.utils.parseUnits(minStaked, 18),
      })),
    );
  };

  return (
    <ContractMethodsForm
      label={ContractNames.TWStakingTierManager}
      methods={stakingTierManagerMethods}
      contract={tWSContract}
    >
      <Form handler={changeTiers} contract={tWSContract}>
        <h2>Tiers manager</h2>
        {tiers.map(({ level }) => (
          <div key={level}>
            <div className="tier">
              <span>{level}:</span>
              <span>Min staked:</span>
              <Input
                className="tier__input"
                value={tiersUpdateData[level]}
                setValue={(value) => handleTiersUpdateData(level, value)}
              />
              <button
                onClick={() => deleteTiers(level)}
                className="tier__delete"
                type="button"
              >
                <img src={closeIcon} alt="delete" />
              </button>
            </div>
          </div>
        ))}
      </Form>
      <Form handler={addTier} contract={tWSContract}>
        <span>Add tier</span>
        <Input
          value={newTierMinStake}
          setValue={setNewTierMinStake}
          placeholder="Min stake"
        />
      </Form>
    </ContractMethodsForm>
  );
};

export default Tiers;
