import React from 'react';
import ReactDOM from "react-dom";
import close from '../../assets/svg/close.svg';

const Modal = ({ children, title, handleClose }) => {
  const propagationHandle = (event) => {
    event.stopPropagation();
  };

  return (
    ReactDOM.createPortal(
      <div className="modal-overlay" onClick={handleClose}>
        <div className="modal" onClick={propagationHandle}>
          <div className="modal__heading">
            <span className="modal__title">{title}</span>
            <button type="button" onClick={handleClose}>
              <img src={close} alt="close"/>
            </button>
          </div>
          {children}
        </div>
      </div>,
      document.querySelector('body')
    )
  );
};

export default Modal;
