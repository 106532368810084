import parseChangeSignersMaster from "./parseChangeSignersMaster";
import parseWithdraw from "./parseWIthdraw";
import parseChangeThreshold from "./parseChangeThreshold";

const parseInput = (methodName, inputs, contracts) => {
	switch (methodName) {
		case "changeSignersMaster":
			return parseChangeSignersMaster(inputs, contracts);
		case "withdraw":
			return parseWithdraw(inputs, contracts);
		case "changeThreshold":
			return parseChangeThreshold(inputs);
		default:
			return {};
	}
}

export default parseInput;
